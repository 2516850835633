import React, {useState} from "react";
import styles from "./KeyLevelUnitTable.module.scss";
import {SelectableTable} from "../../../../widget";
import {TableUtil} from "../../../../../utils/table.util";
import {ConfirmationModal} from "../../../../widget/ConfirmationModal/ConfirmationModal";
import {ComplexService} from "../../../../../services/units-api/complex.service";
import _ from "lodash";
import {Validation} from '@common/utils';
import {appToast} from "../../../../widget/AppToast/AppToast";
import { Ability } from "../../../../../permissions/Ability";
import ability from "../../../../../permissions/Ability";
import {authManager} from "@common/authentication";

interface KeyLevelTableProps {
    units: any;
    complexId: any;
    selectedUnits: any;
    setSelectedUnits: any;
    triggerReload: any;
}

export const KeyLevelUnitTable: React.FunctionComponent<KeyLevelTableProps> = props => {
    const [showConfirmationModal, setShowConfirmationModal] = useState({units: null, show: false});
    const BASE_PUBLIC_URL = Validation.validateAndGetEnvVar("REACT_APP_VACASA_URL");
    const {units, complexId, selectedUnits, setSelectedUnits, triggerReload} = props;

    const openPublicListingLink = unit => {
        window.open(BASE_PUBLIC_URL + "/unit.php?UnitID=" + unit.attributes.legacy_unit_id);
    };

    const handleRemoveUnits = units => {
        if (!Array.isArray(units)) {
            units = [units.id];
        }

        setShowConfirmationModal({units: units, show: true});
    };

    const formatUnits = (units: any) => {
        const menuActions  = ability.can(Ability.Action.Update, Ability.Entity.Complex) ? [
            {
                label: "Public Listing",
                action: openPublicListingLink,
                title: "Public Listing",
                loadingOption: false
            },
            {
                label: "Remove Unit",
                action: handleRemoveUnits,
                title: "Remove Unit",
                loadingOption: true
            }
        ] : [];

        return TableUtil.getFormattedUnits(units, menuActions);
    };

    const handleUnitSelection = units => {
        if (units.length == selectedUnits.length) {
            setSelectedUnits([]);
        } else {
            setSelectedUnits(units);
        }
    };

    if (!units || units.length === 0) {
        return <React.Fragment />;
    }

    const handleClose = () => {
        setShowConfirmationModal({units: null, show: false});
    };

    const handleConfirn = async () => {
        const previuosState = _.cloneDeep(showConfirmationModal);
        setShowConfirmationModal({units: null, show: false});
        const complexService = new ComplexService((authManager.getJwt()));
        await complexService.removeComplexUnits(previuosState.units, complexId);
        appToast.success("Unit removed from complex.");
        triggerReload();
    };

    const formattedUnits = formatUnits(units);
    return (
        <React.Fragment>
            <div className={styles.topSection}>
                <span className={styles.keyUnitLabel}>{formattedUnits.length + " Key-level Units"}</span>
                {selectedUnits.length > 0 ? (
                    <span className={styles.keyUnitLabel}>
                        ({selectedUnits.length} of {formattedUnits.length} selected)
                    </span>
                ) : null}
            </div>
            <SelectableTable
                data={formattedUnits}
                columns={TableUtil.getUnitsTableColumns()}
                selectedItems={selectedUnits}
                updateSelectedItems={handleUnitSelection}
                pageSizeMax={50}
                filterCustomStyle={{background: "rgb(0, 51, 73)"}}
            />
            <ConfirmationModal
                title="Are you sure you want to remove?"
                body="Once removed you will need to add the unit again if you want to undo this change."
                confirmButton="Remove"
                show={showConfirmationModal.show}
                handleConfirmation={handleConfirn}
                handleClose={handleClose}
            />
        </React.Fragment>
    );
};
