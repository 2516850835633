import React, {useState} from "react";
import styles from "./ComplexTab.module.scss";
import {Link, useLocation, useHistory} from "react-router-dom";
import {Loader} from "../../widget";
import Tab from "../../widget/Tab/Tab";
import tabTheme from "./Tab.module.scss";
import {UnitAndClusterTab} from "./unitAndClusterTab/UnitAndClusterTab";
import {InformationTab} from "./informationTab/InformationTab";
import {Validation} from '@common/utils';
import {IconBackArrow} from "../../../assets/icons";
import _ from "lodash";

interface ComplexTabProps {
    complexId: number;
    match: any;
}

export const ComplexTab: React.FunctionComponent<ComplexTabProps> = props => {
    const adminUrl = Validation.validateAndGetEnvVar("REACT_APP_ADMIN_URL");
    const [currentComplex, setCurrentComplex] = useState(null);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [currentView, setCurrentView] = useState(null);
    const location = useLocation();
    const history = useHistory();

    const currentId = _.last(location.pathname.split("/"));
    if (currentId?.trim() === "") {
        history.push("/searchComplex");
    }

    const complexId = parseInt(currentId);
    if (isNaN(complexId)) {
        history.push("/searchComplex");
    }

    const backArrowIconStyleSettings = {
        height: "20px",
        width: "20px",
        fill: "currentColor"
    };

    const viewTabs = [
        {id: "information", label: "Information"},
        {id: "units", label: "Units & Cluster"},
        {id: "amenities", label: "Amenities"}
    ];

    const tabComponents = {
        information: <InformationTab complexId={complexId} onFormChange={hasChanged => setHasUnsavedChanges(hasChanged)} />,
        units: <UnitAndClusterTab complexId={complexId} setComplex={setCurrentComplex} />,
        amenities: <Loader size="14px"></Loader>
    };

    const onTabClick = (tabClicked: string) => {
        if (hasUnsavedChanges) {
            alert("Please save or discard your changes before leaving the page.");
            return;
        }

        setCurrentView({
            name: tabClicked,
            component: tabComponents[tabClicked]
        });

        if (tabClicked === "amenities") {
            window.parent.postMessage(
                {type: "redirect", redirectUrl: `${adminUrl}/admin/dashboard/complexes/index#/complexDetails/104/amenities`},
                "*"
            );

            return;
        }
    };

    if (!currentView) {
        const defaultTab = "units";
        setCurrentView({
            name: defaultTab,
            component: tabComponents[defaultTab]
        });
    }

    return (
        <div>
            <div className={styles.headerWrapper}>
                <div className={styles.headerInfo}>
                    <div className={styles.headerTopRow}>
                        <Link to="/searchComplex" className={styles.backToSearchLink}>
                            <IconBackArrow style={backArrowIconStyleSettings} />
                            Search for Complex
                        </Link>
                    </div>
                    {currentComplex ? (
                        <h1 className={styles.complexName}>
                            {currentComplex.id}/{currentComplex.attributes.name}
                        </h1>
                    ) : null}
                    <hr />
                </div>
                <div className={styles.tabsWrapper}>
                    {viewTabs.map((nextView, i) => {
                        return (
                            <Tab
                                key={i}
                                label={nextView.label}
                                onClick={() => onTabClick(nextView.id)}
                                isSelected={currentView && currentView.name === nextView.id}
                                theme={tabTheme}
                            />
                        );
                    })}
                </div>
            </div>
            <div>
                <React.Fragment>{currentView ? currentView.component : null}</React.Fragment>
            </div>
        </div>
    );
};
