import React, {useEffect, useState} from "react";
import _ from "lodash";
import styles from "./ClusterForm.module.scss";
import commonStyles from "../../../../styles/common.module.scss";
import classNames from "classnames/bind";
import {useHistory} from "react-router-dom";
import {ClusterService} from "../../../../services/units-api/cluster.service";
import {UnitsService} from "@common/units-api";
import {ComplexService} from "../../../../services/units-api/complex.service";
import {COMPLEX_ACTIVE_STATUSES} from "../../../../types/complex.type";
import {TableUtil} from "../../../../utils/table.util";
import Card from "../../../widget/Card/Card";
import {Loader, SelectableTable} from "../../../widget";
import {Validation} from "@common/utils";
import {ConfirmationModal} from "../../../widget/ConfirmationModal/ConfirmationModal";
import {ImageService} from "../../../../services/units-api/image.service";
import {appToast} from "../../../widget/AppToast/AppToast";
import ability, {Ability} from "../../../../permissions/Ability";
import {authManager} from "@common/authentication";

interface ClusterFormProps {
    complexID: string;
    clusterID: string;
    selectedUnits: any;
    setSelectedUnits: any;
    fetchingCluster: any;
    updateFetchingCluster: any;
    setFetchingCluster: any;
    submitDisabled: any;
    setSubmitDisabled: any;
    clusterUnits: any;
    updateClusterUnits: any;
    cluster: any;
    setCluster: any;
    isHeaderLoading: any;
    setIsHeaderLoading: any;
    stateUnits: any;
    reloadUnit: any;
    setReloadUnits: any;
}

export const ClusterForm: React.FunctionComponent<ClusterFormProps> = props => {
    const {
        updateFetchingCluster,
        fetchingCluster,
        selectedUnits,
        clusterID,
        complexID,
        setSelectedUnits,
        setSubmitDisabled,
        updateClusterUnits,
        clusterUnits,
        cluster,
        setCluster,
        setIsHeaderLoading,
        stateUnits,
        reloadUnit,
        setReloadUnits
    } = props;

    const [showConfirmationModal, setShowConfirmationModal] = useState({eventId: "", show: false});
    const [showUnitModal, setShowUnitModal] = useState({unit: null, show: false});
    const [repUnit, setRepUnit] = useState(null);

    const history = useHistory();

    const validateForm = () => {
        return !Validation.isEmptyOrWhiteSpace(cluster.name);
    };

    useEffect(() => {
        (async () => {
            try {
                updateFetchingCluster(true);
                setIsHeaderLoading(true);
                let localCluster = undefined;
                const imageService = new ImageService(authManager.getJwt());
                if (clusterID !== "") {
                    const clusterService = new ClusterService(authManager.getJwt());
                    const clusterResult = await clusterService.getClusterWithUnitsInclude(Number(clusterID));
                    localCluster = clusterResult;
                    const mappedCluster = {
                        id: localCluster.data.id,
                        name: localCluster.data.attributes.name,
                        status: localCluster.data.attributes.active_status,
                        description: localCluster.data.attributes.internal_notes
                    };
                    setCluster(mappedCluster);

                    let localUnits = _.uniqBy(localCluster.included, "id");
                    const representativeUnit = localCluster.data.relationships.rep_unit.data;

                    if (!_.isNil(representativeUnit)) {
                        const found = localUnits.find((x: any) => x.id === representativeUnit.id) as any;

                        if (found) {
                            found.isRepresentative = true;
                            setRepUnit(found);
                        }
                    }

                    if (!_.isEmpty(localUnits)) {
                        localUnits = await imageService.addImagesToUnits(localUnits);
                    }

                    updateClusterUnits(localUnits);
                } else {
                    let clusterUnits = stateUnits;
                    if (!_.isEmpty(clusterUnits)) {
                        const unitService = new UnitsService(authManager.getJwt());
                        clusterUnits = await unitService.searchUnits({
                            ids: stateUnits.join(",")
                        });

                        const units = await imageService.addImagesToUnits(clusterUnits);
                        updateClusterUnits(units);
                    }
                }
            } catch (e) {
                console.error(e);
                history.push(`/searchComplex`);
            } finally {
                updateFetchingCluster(false);
                setIsHeaderLoading(false);
            }
        })();
    }, [reloadUnit]);

    useEffect(() => {
        setSubmitDisabled(!validateForm());
    }, [cluster, repUnit]);

    const handlePropertyChange = (e: any) => {
        const target = e.target as HTMLInputElement;
        const previousCluster = _.cloneDeep(cluster);
        const updatedCluster = {...previousCluster, [target.name]: target.value};
        setCluster(updatedCluster);
    };

    const handleUpdateUnit = updatedSelectedUnits => {
        const repUnit = clusterUnits.find(x => x.isRepresentative);
        if (!_.isNil(repUnit)) {
            _.remove(updatedSelectedUnits, x => x == repUnit.id);
        }
        setSelectedUnits(updatedSelectedUnits);
    };

    const activeStatusesDropdown = COMPLEX_ACTIVE_STATUSES.map(status => {
        return (
            <option key={status.id} value={status.slug}>
                {status.title}
            </option>
        );
    });

    const validateMaxOccupancy = selectedUnit => {
        let failingUnits = [];
        const selectedUnitMaxOccupancy = selectedUnit?.attributes?.max_occupancy;

        clusterUnits.forEach(unit => {
            if (unit.attributes.max_occupancy < selectedUnitMaxOccupancy) failingUnits.push(`, unit id ${unit.attributes.legacy_unit_id}`);
        });

        return failingUnits;
    };

    const handleRepUnitChange = unit => {
        const failingMaxOccupancyUnits = validateMaxOccupancy(unit);
        if (failingMaxOccupancyUnits.length > 0) {
            appToast.error(
                `This unit has a max occupancy that is greater than the max occupancy of one or more cluster units${failingMaxOccupancyUnits}. The max occupancy must be changed or a different unit must be selected as the Rep Unit. Please contact channelteam@vacasa.com if making changes to Cluster or Rep Units`
            );
        } else {
            setRepUnit(unit);
            setShowConfirmationModal({
                eventId: unit.id,
                show: true
            });
        }
    };

    const menuActions = [];
    if (ability.can(Ability.Action.Update, Ability.Entity.Cluster)) {
        menuActions.push({
            label: "Set as Rep Unit",
            action: unitEvent => {
                handleRepUnitChange(unitEvent);
                const units = _.cloneDeep(clusterUnits);
                for (let unit of units) {
                    unit.isRepresentative = unit.id == unitEvent.id;
                }

                if (clusterID == "") {
                    updateClusterUnits(units);
                    return;
                }
            },
            title: "Set as Rep Unit",
            loadingOption: false
        });

        if (clusterID != "") {
            menuActions.push({
                label: "Remove from Cluster",
                action: unitEvent => {
                    setShowUnitModal({unit: unitEvent, show: true});
                },
                title: "Remove from Cluster",
                loadingOption: false
            });
        }
    }

    const formattedUnits = TableUtil.getFormattedUnits(clusterUnits, menuActions);
    const handleCloseModal = () => {
        const previousModal = _.cloneDeep(showConfirmationModal);
        setShowConfirmationModal({...previousModal, show: false});
    };

    const handleConfirmationModal = async () => {
        try {
            const info = _.cloneDeep(showConfirmationModal);
            setShowConfirmationModal({eventId: "", show: false});
            const clusterService = new ClusterService(authManager.getJwt());
            await clusterService.updateClusterRepUnit(Number(clusterID), info.eventId);
            const units = _.cloneDeep(clusterUnits);
            for (let unit of units) {
                unit.isRepresentative = unit.id == info.eventId;
            }
            appToast.success("Unit Representative updated");
            updateClusterUnits(units);
        } catch (error) {
            console.error(error);
        }
    };

    if (fetchingCluster) {
        return (
            <Card>
                <Loader size="12px" />
            </Card>
        );
    }

    const handleCloseUnitModal = () => {
        const previousModal = _.cloneDeep(showUnitModal);
        setShowUnitModal({...previousModal, show: false});
    };

    const renderRepUnitModal = () => {
        return (
            <ConfirmationModal
                title="Representative Unit"
                body="Selecting this unit as the rep unit and saving will override the current rep unit."
                confirmButton="Set as Rep Unit"
                show={showConfirmationModal.show}
                handleClose={handleCloseModal}
                handleConfirmation={handleConfirmationModal}
            />
        );
    };

    const handleConfirmRemoveUnitModal = async () => {
        try {
            const previousModal = _.cloneDeep(showUnitModal);
            setShowUnitModal({unit: null, show: false});
            const clusterService = new ClusterService(authManager.getJwt());
            const complexService = new ComplexService(authManager.getJwt());
            await clusterService.removeClusterUnits([previousModal.unit.id], Number(clusterID));
            await complexService.createComplexUnits([previousModal.unit.id], Number(complexID));
            appToast.success("Unit removed from cluster");
            setReloadUnits(reloadUnit + 1);
        } catch (e) {
            appToast.error("Error removing unit from cluster.");
        }
    };

    const renderRemoveUnitModal = () => {
        return (
            <ConfirmationModal
                title="Are you sure you want to remove this unit?"
                body=""
                confirmButton="Remove from Cluster"
                show={showUnitModal.show}
                handleClose={handleCloseUnitModal}
                handleConfirmation={handleConfirmRemoveUnitModal}
            />
        );
    };

    return (
        <div>
            <React.Fragment>
                <div className={styles.topSection} />
                <div className={styles.clusterFormBackground}>
                    <div className={styles.clusterFormPadding}>
                        <div className={styles.horizontalInputWrapper} style={{width: "555px"}}>
                            <div className={styles.warning}>
                                <div className="alert alert-warning" role="alert">
                                    <span className={commonStyles.warningIcon}>&nbsp;</span>
                                    <span>This field does not currently update the channel partner listings.</span>
                                </div>
                            </div>
                        </div>

                        <div className={styles.horizontalInputWrapper}>
                            <div>
                                <label className={commonStyles.defaultLabel} htmlFor="complex-name">
                                    Cluster Name
                                </label>

                                <div className="input-group">
                                    <input
                                        id="complex-name"
                                        type="text"
                                        name="name"
                                        className={commonStyles.defaultInputWithWarning}
                                        value={cluster.name}
                                        placeholder="Enter Cluster Name"
                                        onChange={handlePropertyChange}
                                    />
                                </div>
                            </div>
                            <div>
                                <label className={commonStyles.defaultLabel} htmlFor="complex-id">
                                    Cluster ID
                                </label>
                                <input id="complex-id" type="text" className={commonStyles.defaultInput} value={cluster.id} disabled />
                            </div>
                            <div className={styles.activeStatusRow} style={{marginRight: "0px"}}>
                                <label className={commonStyles.defaultLabel} htmlFor="complex-active">
                                    Status
                                </label>
                                <select
                                    id="complex-active"
                                    name="status"
                                    className={commonStyles.defaultInput}
                                    value={cluster.status}
                                    onChange={handlePropertyChange}
                                >
                                    {activeStatusesDropdown}
                                </select>
                            </div>
                        </div>
                        <div>
                            <label className={commonStyles.defaultLabel} htmlFor="complex-description">
                                Description
                            </label>
                            <textarea
                                id="complex-description"
                                className={classNames(commonStyles.defaultInputWithWarning, styles.descriptionField)}
                                name="description"
                                value={cluster.description}
                                onChange={handlePropertyChange}
                                style={{backgroundPositionY: "10px"}}
                            />
                        </div>
                    </div>
                    {
                        <SelectableTable
                            data={formattedUnits}
                            columns={TableUtil.getUnitsTableColumns()}
                            selectedItems={selectedUnits}
                            updateSelectedItems={handleUpdateUnit}
                            pageSizeMax={50}
                            filterCustomStyle={{background: "rgb(0, 51, 73)"}}
                            groupCustomStyle={(state, rowInfo, column) => {
                                if (!rowInfo) {
                                    return;
                                }
                                if (rowInfo.original.rawUnit.isRepresentative) {
                                    return {
                                        style: {
                                            background: "#007eca"
                                        }
                                    };
                                }
                            }}
                            tdCustomStyle={(state, rowInfo, column, instance) => {
                                const tdStyle = {
                                    textAlign: "left",
                                    wordWrap: "break-word",
                                    padding: "13px 8px",
                                    color: "#2C3439",
                                    fontSize: "14px",
                                    visibility: ""
                                };

                                if (!rowInfo) {
                                    return {style: tdStyle};
                                }
                                if (rowInfo.original.rawUnit.isRepresentative) {
                                    tdStyle.color = "white";
                                    if (column.id == "actions" || column.id == "_selector") {
                                        tdStyle.visibility = "hidden";
                                    }
                                    return {style: tdStyle};
                                }
                                return {style: tdStyle};
                            }}
                        />
                    }
                </div>
            </React.Fragment>
            {renderRepUnitModal()}
            {renderRemoveUnitModal()}
        </div>
    );
};
