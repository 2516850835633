import React, {useState, useEffect} from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import styles from "./ComplexTable.module.scss";
import {Link} from "react-router-dom";
import {Loader} from "../../../widget";
import {ComplexService} from "../../../../services/units-api/complex.service";
import {authManager} from "@common/authentication";


export const ComplexTable: React.FunctionComponent<any> = () => {
    const [complexes, setComplexes] = useState([]);
    const [isFetchingData, setIsFetchingData] = useState(true);

    useEffect(() => {
        fetchComplexes();
    }, []);

    const fetchComplexes = async () => {
        try {
            const complexService = new ComplexService((authManager.getJwt()));
            const response = await complexService.getComplexes();
            setComplexes(response);
            setIsFetchingData(false);
        } catch (e) {
            console.error(e);
            setIsFetchingData(false);
        }
    };

    const columns = [
        {
            Header: () => <div style={{textAlign: "left"}}>Complex ID</div>,
            accessor: "complexID",
            width: 100
        },
        {
            Header: () => <div style={{textAlign: "left"}}>Complex Name</div>,
            accessor: "complexName"
        }
    ];

    const pageSize = complexes.length;

    const formattedComplexForTable = complexes => {
        let data = [];
        complexes.forEach(function(complex) {
            data.push({
                complexID: complex.id,
                complexName: (
                    <Link
                        to={{
                            pathname: `/complexTab/${complex.id}`,
                            state: {complexId: complex.id}
                        }}
                    >
                        {complex.attributes.name}
                    </Link>
                )
            });
        });

        return data;
    };

    return (
        <div className={styles.panel}>
            <div className="panel panel-default">
                <div className="panel-table">
                    {isFetchingData ? (
                        <Loader size="12px" />
                    ) : (
                        <ReactTable
                            data={formattedComplexForTable(complexes)}
                            columns={columns}
                            className="-striped -highlight table-padding"
                            showPaginationBottom={false}
                            resizable={true}
                            pageSize={pageSize}
                            getTheadThProps={() => {
                                return {
                                    style: {
                                        textAlign: "left",
                                        background: "#003349",
                                        color: "#FFFFFF",
                                        padding: "8px",
                                        fontSize: "14px"
                                    }
                                };
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
