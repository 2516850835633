import {toast} from "react-toastify";
import {style} from "glamor";

export const appToast = {
    success(msg, options = {}) {
        return toast.success(msg, {
            ...options,
            className: "toast-success-container toast-success-container-after",
            progressClassName: style({
                background: "#34A853",
            }) as any,
        });
    },
    error(msg, options = {}) {
        return toast.error(msg, {
            ...options,
            className: "toast-error-container toast-error-container-after",
            progressClassName: style({
                background: "#EE0022",
            }) as any,
        });
    },
};
