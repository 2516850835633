import * as React from "react";
import {useLocation, useHistory} from "react-router-dom";
import {ComplexForm} from "../../complexManage/complexForm/ComplexForm";
import _ from "lodash";

interface InformationTabProps {
    complexId: number;
    onFormChange: (hasChanged: boolean) => void;
}

export const InformationTab: React.FunctionComponent<InformationTabProps> = props => {
    const location = useLocation();
    const history = useHistory();

    const currentId = _.last(location.pathname.split("/"));
    if (currentId?.trim() === "") {
        history.push("/searchComplex");
    }

    const complexId = parseInt(currentId);
    if (isNaN(complexId)) {
        history.push("/searchComplex");
    }

    const {onFormChange} = props;
    return (
        <div style={{marginTop: "35px"}}>
            <ComplexForm complexId={complexId} onFormChange={onFormChange} />
        </div>
    );
};
