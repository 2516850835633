import * as React from "react";
import styles from "./ComplexHeader.module.scss";
import {Link} from "react-router-dom";
import {Can} from "../../../../permissions/Can";
import { Ability } from "../../../../permissions/Ability";

export const ComplexHeader: React.FunctionComponent<any> = () => {

    return (
        <React.Fragment>
            <div className={styles.headerWrapper}>
                <div>
                    <h4>Search for a Complex</h4>
                </div>
                <div>
                    <Can I={Ability.Action.Create} a={Ability.Entity.Complex}>
                        <Link className="defaultLink" to="/complexManage">
                            Create Complex
                        </Link>
                    </Can>
                </div>
            </div>
        </React.Fragment>
    );
};
