import React from "react";
import {Button, Modal} from "react-bootstrap";

interface ConfirmationModalProps {
    show: boolean;
    handleConfirmation: any;
    handleClose: any;
    title: string;
    body: string;
    confirmButton: string;
}
export const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = props => {
    const {show, handleClose, handleConfirmation, title, body, confirmButton} = props;

    return (
        <React.Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirmation}>
                        {confirmButton}
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};
