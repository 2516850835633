import React, {useState, useEffect} from "react";
import {useHistory, RouteChildrenProps} from "react-router-dom";
import {UnitAddHeader} from "./unitAddHeader/UnitAddHeader";
import {UnitAddSearchForm} from "./unitAddSearchForm/UnitAddSearchForm";
import {UnitAddList} from "./unitAddList/UnitAddList";
import {UnitsService} from "@common/units-api";
import {ComplexService} from "../../../services/units-api/complex.service";
import {appToast} from "../../widget/AppToast/AppToast";
import * as _ from "lodash";
import {authManager} from "@common/authentication";

export const UnitAdd: React.FunctionComponent<RouteChildrenProps<{complexID}>> = props => {
    const [isSearchPending, setIsSearchPending] = useState(false);
    const [isAddComplexUnitPending, setIsAddComplexUnitPending] = useState(false);
    const [showSearchForm, setShowSearchForm] = useState(true);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [searchUnits, setSearchUnits] = useState([]);

    const complexID = props.match.params.complexID;
    const history = useHistory();
    const checkComplexID = () => {
        if (!complexID) {
            history.push("/searchComplex");
        }
    };

    useEffect(checkComplexID, []);

    const handleUnitSearchEvent = async (searchData: {[key: string]: any}) => {
        try {
            setIsSearchPending(true);
            setShowSearchResults(false);
            const unitService = new UnitsService(authManager.getJwt());
            const response = await unitService.searchUnits(searchData);
            setSearchUnits(response);
        } catch (e) {
            appToast.error(e.toString());
        } finally {
            setIsSearchPending(false);
            setShowSearchResults(true);
        }
    };

    const toggleSearchForm = () => {
        showSearchForm ? setShowSearchForm(false) : setShowSearchForm(true);
    };

    const handleUnitSelection = updatedSelectedUnits => {
        setSelectedUnits(updatedSelectedUnits);
    };

    const handleAddUnits = async () => {
        try {
            setIsAddComplexUnitPending(true);
            const complexService = new ComplexService((authManager.getJwt()));
            await complexService.createComplexUnits(selectedUnits, complexID);
            const unitString = selectedUnits.length > 1 ? "units" : "unit";
            appToast.success(`Added ${selectedUnits.length} ${unitString} to complex.`);
            history.push(`/complexTab/${complexID}`);
        } catch (err) {
            const errors = _.get(err, "response.data.message", ["Error adding units"]);
            for (let error of errors) {
                appToast.error(error);
            }
        } finally {
            setIsAddComplexUnitPending(false);
        }
    };

    return (
        <React.Fragment>
            <UnitAddHeader
                complexID={complexID}
                selectedUnitsLength={selectedUnits.length}
                showSearchResults={showSearchResults}
                handleAddUnits={handleAddUnits}
                isAddComplexUnitPending={isAddComplexUnitPending}
                clusterID={undefined}
            />
            <UnitAddSearchForm handleUnitSearchEvent={handleUnitSearchEvent} showSearchForm={showSearchForm} isSearchPending={isSearchPending} />
            {showSearchResults ? (
                <UnitAddList
                    units={searchUnits}
                    selectedUnits={selectedUnits}
                    handleUnitSelection={handleUnitSelection}
                    toggleSearchForm={toggleSearchForm}
                    showSearchForm={showSearchForm}
                />
            ) : null}
        </React.Fragment>
    );
};
