import React, {useEffect} from "react";
import {Complex, ComplexStatusKeys} from "../../../../types/complex.type";
import {useState} from "react";
import {Loader, Button} from "../../../widget";
import actionStyles from "./ComplexForm.module.scss";
import commonStyles from "../../../../styles/common.module.scss";
import classNames from "classnames";
import {useHistory} from "react-router-dom";
import {ComplexUtil} from "../../../../utils/complex.util";
import {ComplexService} from "../../../../services/units-api/complex.service";
import {appToast} from "../../../widget/AppToast/AppToast";
import {Can} from "../../../../permissions/Can";
import _ from "lodash";
import { Ability } from "../../../../permissions/Ability";
import {authManager} from "@common/authentication";

interface ComplexFormProps {
    complexId?: number;
    onFormChange: (hasChanged: boolean) => void;
}

const emptyComplex: Complex = {
    id: "",
    description: "",
    internalNotes: "",
    name: " ",
    status: ComplexStatusKeys.INACTIVE,
    firstAddress: "",
    city: "",
    countryCode: "",
    secondAddress: "",
    state: "",
    zip: ""
};

export const ComplexForm: React.FunctionComponent<ComplexFormProps> = props => {
    const notificationProps = ["name", "status", "description", "internalNotes"];
    const [storedComplex, setStoredComplex] = useState<Complex>(emptyComplex);
    const [complex, setComplex] = useState<Complex>(emptyComplex);
    const [submitPending, setSubmitPending] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {complexId, onFormChange} = props;
    const history = useHistory();

    useEffect(() => {
        if (!complexId) {
            return;
        }

        (async () => {
            try {
                setIsLoading(true);
                const complexService = new ComplexService((authManager.getJwt()));
                const currentComplex = await complexService.getComplex(complexId);
                const mappedComplex = ComplexUtil.mapToLocalComplex({...currentComplex.attributes, id: currentComplex.id});
                setComplex(mappedComplex);
                setStoredComplex(mappedComplex);
            } catch (e) {
                appToast.error("Error getting complex");
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    const handleFormError = (error, defaultMessage) => {
        console.error(error);
        if (_.has(error, "isHandled")) {
            for (let errorMessage of error.messages) {
                appToast.error(errorMessage);
            }
            return;
        }

        appToast.error(defaultMessage);
    };

    const addComplex = async () => {
        try {
            setSubmitPending(true);
            const complexService = new ComplexService((authManager.getJwt()));
            const response = await complexService.createComplex(ComplexUtil.mapToComplexService(complex));
            appToast.success("Complex created successfully!");
            setSubmitPending(false);
            history.push(`/complexTab/${response.id}`, {complexId: response.id});
        } catch (error) {
            handleFormError(error, "Error adding complex");
            setSubmitPending(false);
        }
    };

    const updateComplex = async () => {
        try {
            setSubmitPending(true);
            const complexService = new ComplexService((authManager.getJwt()));
            await complexService.updateComplex(ComplexUtil.mapToComplexService(complex));
            appToast.success("Complex updated successfully!");
            const updatedComplex = _.cloneDeep(complex);
            setStoredComplex(updatedComplex);
            setSubmitPending(false);
            onFormChange(false);
        } catch (error) {
            handleFormError(error, "Error updating complex");
            setSubmitPending(false);
        }
    };

    const submitForm = async () => {
        if (complexId) {
            await updateComplex();
            return;
        }

        await addComplex();
    };

    const handlePropertyChange = (e: any) => {
        const target = e.target as HTMLInputElement;

        if (notificationProps.includes(target.name)) {
            onFormChange(storedComplex[target.name] !== target.value);
        }

        const previousComplex = _.cloneDeep(complex);
        const updatedComplex = {...previousComplex, [target.name]: target.value};
        setComplex(updatedComplex);
    };

    if (isLoading) {
        return <Loader size="14px" />;
    }

    return (
        <React.Fragment>
            <div className={actionStyles.actionBarWrapper}>
                <div className={actionStyles.warning}>
                    <div className="alert alert-warning" role="alert">
                        <span className={commonStyles.warningIcon}>&nbsp;</span>
                        <span>This field does not currently update the channel partner listings.</span>
                    </div>
                </div>
                <div>
                    <Can I={Ability.Action.Update} a={Ability.Entity.Complex}>
                        <Button type="primary" width="128px" pending={submitPending} onClick={submitForm}>
                            {_.isNil(complexId) ? "Create" : "Save"}
                        </Button>
                    </Can>
                </div>
            </div>
            <div className={actionStyles.horizontalInputWrapper}>
                <div>
                    <label className={commonStyles.defaultLabel} htmlFor="complex-name">
                        Complex Name
                    </label>
                    <div className="input-group">
                        <input
                            id="complex-name"
                            name="name"
                            type="text"
                            className={commonStyles.defaultInputWithWarning}
                            placeholder="..."
                            value={complex.name}
                            onChange={handlePropertyChange}
                        />
                    </div>
                </div>
                <div>
                    <label className={commonStyles.defaultLabel} htmlFor="complex-id">
                        Complex ID
                    </label>
                    <input
                        id="complex-id"
                        type="text"
                        className={commonStyles.defaultInput}
                        placeholder="..."
                        value={complexId ? complexId : ""}
                        disabled
                    />
                </div>
                <div className={actionStyles.activeStatusRow}>
                    <label className={commonStyles.defaultLabel} htmlFor="complex-active">
                        Active
                    </label>
                    <select
                        id="complex-active"
                        name="status"
                        value={complex.status}
                        className={commonStyles.defaultInputWithWarning}
                        onChange={handlePropertyChange}
                        style={{backgroundPosition: "right calc(.375em + 0.6rem) center"}}
                    >
                        {Object.keys(ComplexStatusKeys).map(status => (
                            <option value={status === "INACTIVE" ? "not_active" : status.toLocaleLowerCase()} key={status}>
                                {`${status.charAt(0)}${status.slice(1).toLowerCase()}`}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className={actionStyles.horizontalInputWrapper}>
                <div>
                    <label className={commonStyles.defaultLabel} htmlFor="address_1">
                        Address 1
                    </label>
                    <input
                        id="address_1"
                        name="firstAddress"
                        type="text"
                        className={commonStyles.defaultInputWithWarning}
                        placeholder="..."
                        value={complex.firstAddress}
                        onChange={handlePropertyChange}
                    />
                </div>
                <div>
                    <label className={commonStyles.defaultLabel} htmlFor="address_2">
                        Address 2
                    </label>
                    <input
                        id="address_2"
                        name="secondAddress"
                        type="text"
                        className={commonStyles.defaultInputWithWarning}
                        placeholder="..."
                        value={complex.secondAddress}
                        onChange={handlePropertyChange}
                    />
                </div>
            </div>

            <div>
                <div className={actionStyles.horizontalInputWrapper}>
                    <div>
                        <label className={commonStyles.defaultLabel} htmlFor="city">
                            City
                        </label>
                        <input
                            id="city"
                            name="city"
                            type="text"
                            className={commonStyles.defaultInputWithWarning}
                            placeholder="..."
                            value={complex.city}
                            onChange={handlePropertyChange}
                        />
                    </div>
                    <div>
                        <label className={commonStyles.defaultLabel} htmlFor="country_code">
                            Country Code
                        </label>
                        <input
                            id="country_code"
                            name="countryCode"
                            type="text"
                            className={commonStyles.defaultInputWithWarning}
                            placeholder="..."
                            value={complex.countryCode}
                            maxLength={2}
                            onChange={handlePropertyChange}
                        />
                    </div>
                    <div>
                        <label className={commonStyles.defaultLabel} htmlFor="state">
                            State
                        </label>
                        <input
                            id="state"
                            name="state"
                            type="text"
                            className={commonStyles.defaultInputWithWarning}
                            placeholder="..."
                            value={complex.state}
                            onChange={handlePropertyChange}
                        />
                    </div>
                    <div>
                        <label className={commonStyles.defaultLabel} htmlFor="zip">
                            Zip
                        </label>
                        <input
                            id="zip"
                            name="zip"
                            type="text"
                            className={commonStyles.defaultInputWithWarning}
                            placeholder="..."
                            value={complex.zip}
                            onChange={handlePropertyChange}
                        />
                    </div>
                </div>
            </div>
            <div className={actionStyles.horizontalInputWrapper}>
                <div>
                    <label className={commonStyles.defaultLabel} htmlFor="complex-description">
                        Description
                    </label>
                    <textarea
                        id="complex-description"
                        name="description"
                        className={classNames(commonStyles.defaultInputWithWarning, actionStyles.descriptionField)}
                        value={complex.description}
                        onChange={handlePropertyChange}
                        style={{backgroundPositionY: "10px"}}
                    />
                </div>
                <div>
                    <label className={commonStyles.defaultLabel} htmlFor="complex-internal-notes">
                        Internal Notes
                    </label>
                    <textarea
                        id="complex-internal-notes"
                        name="internalNotes"
                        className={classNames(commonStyles.defaultInput, actionStyles.descriptionField)}
                        value={complex.internalNotes}
                        onChange={handlePropertyChange}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};
