import React from "react";
import buttonStyles from "./Button.module.scss";
import BetterLoader from "../BetterLoader/BetterLoader";
import classNames from "classnames";
import {Icon} from "..";

export function Button({
    type = "primary",
    onClick = undefined,
    disabled = false,
    pending = false,
    isSelected = false,
    width = "auto",
    fontSize = "16px",
    alignSelf = "center",
    children = {},
    className = "",
    title = "",
    iconName = null,
    buttonType = null,
}) {
    const selectedStyle = isSelected ? buttonStyles[`${type}Selected`] : "";
    const pendingStyle = pending ? buttonStyles["pendingStyle"] : "";

    const getIcon = (iconName) => {
        if (iconName === null) return null;
        return <Icon className={buttonStyles.icon} xLinkHref={"#v-icon-ui-" + iconName} sizeSettings="0 0 16 16" />;
    };

    return (
        <button
            style={{width: width, fontSize: fontSize, alignSelf: alignSelf}}
            className={classNames(buttonStyles[type], selectedStyle, pendingStyle, className)}
            onClick={onClick}
            disabled={pending || disabled}
            title={title}
            type={buttonType}
        >
            {pending ? (
                <BetterLoader size="8px" color={type === "borderless" ? "#007eca" : "#fff"} />
            ) : (
                <span className={buttonStyles.contentWrapper}>
                    {getIcon(iconName)}
                    <span>{children}</span>
                </span>
            )}
        </button>
    );
}
