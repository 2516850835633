import {Complex} from "../types/complex.type";

export class ComplexUtil {
    public static mapToComplexService(complex: Complex): any {
        if (!complex) return null;

        return {
            id: complex.id,
            name: complex.name,
            description: complex.description,
            active_status: complex.status,
            address: {
                address_1: complex.firstAddress,
                address_2: complex.secondAddress,
                city: complex.city,
                country_code: complex.countryCode,
                zip: complex.zip,
                state: complex.state,
            },
            internal_notes: complex.internalNotes,
        };
    }

    public static mapToLocalComplex(complex: any): Complex {
        if (!complex) {
            return;
        }

        return {
            id: complex.id,
            name: complex.name,
            description: complex.description,
            internalNotes: complex.internal_notes,
            status: complex.active_status,
            firstAddress: complex.address.address_1 || "",
            secondAddress: complex.address.address_2 || "",
            city: complex.address.city || "",
            countryCode: complex.address.country_code || "",
            zip: complex.address.city || "",
            state: complex.address.state || "",
        };
    }
}
