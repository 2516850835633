import React, {useState} from "react";
import menuDropdownStyles from "./MenuDropdown.module.scss";

const MenuActions = ({actions, show, toggle}) => {
    const callActionAndCloseMenu = (action, args) => {
        toggle();
        action(args);
    };

    const showMenu = show ? {display: "block"} : {display: "none"};

    const actionsList = actions.map(({action, args, label, title, loadingOption, context = null}, i) => {
        return (
            <li key={i} onClick={e => callActionAndCloseMenu(action, args)} title={title}>
                <a target="_blank" className="dropdown-item">
                    {label}
                </a>
            </li>
        );
    });

    return (
        <ul className="dropdown-menu dropdown-menu-right" role="menu" style={Object.assign({marginTop: "0px"}, showMenu)}>
            {actionsList}
        </ul>
    );
};

export function MenuDropdown({items, enabledTitle = null, disabledTitle = null}) {
    const [show, toggle] = useState(false);

    const toggleShowMenu = () => {
        toggle(!show);
    };

    const Menu = () => {
        const disabled = items.length < 1;
        const buttonStyle = `${menuDropdownStyles.menuActionsBtn} ${disabled ? menuDropdownStyles.menuActionsBtnDisabled : ""}`;

        return (
            <li className={`dropdown dropdown__menu ${menuDropdownStyles.dropdownActions}`} title={disabled ? disabledTitle : enabledTitle}>
                <div>
                    <button onClick={toggleShowMenu} className={buttonStyle} />
                    <MenuActions actions={items} show={show} toggle={() => toggleShowMenu()} />
                </div>
            </li>
        );
    };

    return <Menu />;
}
