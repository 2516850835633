import React, {useState} from "react";
import classNames from "classnames";
import styles from "./UnitAddSearchForm.module.scss";
import commonStyles from "../../../../styles/common.module.scss";
import {Button} from "../../../widget";
import {AlertMessageComponent} from "../../../alertMessage/AlertMessageComponent";
import _ from "lodash";

interface UnitAddHeaderProps {
    handleUnitSearchEvent: Function;
    isSearchPending: boolean;
    showSearchForm: boolean;
    availableToAdd?: boolean;
    unitMsg?: number
}

export const UnitAddSearchForm: React.FunctionComponent<UnitAddHeaderProps> = (props) => {
    const initialFormState = {
        unitIDOrCode: "",
        propertyName: "",
        streetAddress: "",
        city: "",
        zipCode: "",
    };
    const [searchForm, setSearchFormValues] = useState(initialFormState);

    //Takes an input event and updates the appropriate key in the form state
    const updateForm = (e) => {
        setSearchFormValues({...searchForm, [e.target.name]: e.target.value});
    };

    const submitButtonDisabled = Object.values(searchForm).every((fieldValue) => fieldValue === "");

    return (
        <div
            className={classNames(styles.container, {
                [`${styles.searchCollapsed}`]: !props.showSearchForm,
            })}
        >
            <div className={"row"}>
                <div className={"col-sm-9"}>
                    <div className={styles.formContainer}>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                props.handleUnitSearchEvent(searchForm);
                            }}
                        >
                            <div className={styles.formRow}>
                                <div className={styles.formElementContainer}>
                                    <input
                                        type="text"
                                        name="unitIDOrCode"
                                        value={searchForm.unitIDOrCode.toUpperCase()} //force unit codes to display capitalized
                                        onChange={(e) => updateForm(e)}
                                        className={commonStyles.defaultInput}
                                        placeholder="Unit Code / ID"
                                    />
                                </div>
                            </div>
                            <div className={styles.formRow}>
                                <div className={styles.formElementContainer}>
                                    <input
                                        type="text"
                                        name="propertyName"
                                        value={searchForm.propertyName}
                                        onChange={(e) => updateForm(e)}
                                        className={commonStyles.defaultInput}
                                        placeholder="Property Name"
                                    />
                                </div>
                            </div>
                            <div className={styles.formRow}>
                                <div className={styles.formElementContainer}>
                                    <input
                                        type="text"
                                        name="streetAddress"
                                        value={searchForm.streetAddress}
                                        onChange={(e) => updateForm(e)}
                                        className={commonStyles.defaultInput}
                                        placeholder="Street Address"
                                    />
                                </div>
                            </div>
                            <div className={styles.formRow}>
                                <div className={styles.formElementContainer}>
                                    <input
                                        type="text"
                                        name="city"
                                        value={searchForm.city}
                                        onChange={(e) => updateForm(e)}
                                        className={commonStyles.defaultInput}
                                        placeholder="City"
                                    />
                                </div>
                                <div className={classNames(styles.formElementContainer, styles.zipInput)}>
                                    <input
                                        type="text"
                                        name="zipCode"
                                        value={searchForm.zipCode}
                                        onChange={(e) => updateForm(e)}
                                        className={commonStyles.defaultInput}
                                        placeholder="Zip Code"
                                    />
                                </div>
                            </div>
                            <Button
                                disabled={submitButtonDisabled}
                                title={submitButtonDisabled ? "Please input some criteria to search." : ""}
                                type="primary"
                                width="128"
                                alignSelf={"left"}
                                pending={props.isSearchPending}
                                className={styles.searchButton}
                            >
                                Search
                            </Button>
                        </form>
                    </div>
                </div>
                {!_.isNil(props.availableToAdd) && !props.availableToAdd ?
                    <div className={"col-sm-3"}>
                        <div className={styles.messageContainer}>
                            <AlertMessageComponent
                                text={`This unit has max occupancy that is less than the max occupancy of the Rep Unit, unit id ${props.unitMsg}. The max occupancy must be changed for this unit or in Rep Unit before this unit can be added. Please contact channelteam@vacasa.com if making changes to Cluster or Rep Units`}
                                className={"error"}
                            />
                        </div>
                    </div>
                : <React.Fragment/> }
            </div>
        </div>
    );
};
