import {AppService} from "@common/units-api";
import {Configuration} from "@common/configuration";
import * as _ from "lodash";

export class ImageService extends AppService {
    private unitServiceEndpointV2: string;

    constructor(token: string) {
        super(token);
        this.unitServiceEndpointV2 = Configuration.unitsServiceConfig().apiV2;
    }

    public async addImagesToUnits(units: Array<any>): Promise<Array<any>> {
        try {
            if (_.isEmpty(units)) {
                return units;
            }

            const unitImages = await this.getUnitImages(units);
            return this.mapUnitImages(units, unitImages);
        } catch (e) {
            console.error(e);
            return units;
        }
    }

    private mapUnitImages(units: Array<any>, unitImages: Array<any>): Array<any> {
        const enhancedUnits = [];
        for (let unit of units) {
            const found = unitImages.find((u) => u.attributes.legacy_unit_id == unit.attributes.legacy_unit_id);
            if (found) {
                unit.attributes.images = found.attributes.images;
            }
            enhancedUnits.push(unit);
        }

        return enhancedUnits;
    }

    private async getUnitImages(units: Array<any>): Promise<Array<any>> {
        const legacyUnitIds = units.map((u) => u.attributes.legacy_unit_id).toString();
        const unitsImageCacheData = this.getUnitImagesFromCache(legacyUnitIds);
        if (!_.isNil(unitsImageCacheData)) {
            return unitsImageCacheData;
        }

        const unitImagesData = await this.fetchUnitImages(legacyUnitIds);
        this.saveDataInCache(legacyUnitIds, unitImagesData);
        return unitImagesData;
    }

    private async fetchUnitImages(unitIds: string): Promise<any> {
        return (
            await this.httpService.get(`${this.unitServiceEndpointV2}/unit-image`, {
                headers: this.getHeaders(),
                params: {
                    "filter[legacy_unit_id][in]": unitIds,
                    "page[size]": 500,
                },
            })
        ).data.data;
    }

    private getUnitImagesFromCache(ids: string): Array<any> | null {
        try {
            return JSON.parse(localStorage.getItem(`${Configuration.UNIT_IMAGES_CACHE_KEY}:${ids}`));
        } catch (e) {
            return null;
        }
    }

    private saveDataInCache(ids: string, data: any): void {
        if (data?.length > 0) {
            localStorage.setItem(`${Configuration.UNIT_IMAGES_CACHE_KEY}:${ids}`, JSON.stringify(data));
        }
    }
}
