export interface Complex {
    id: string;
    name: string;
    status: string;
    internalNotes: string;
    description: string;
    firstAddress: string;
    secondAddress: string;
    countryCode: string;
    state: string;
    city: string;
    zip: string;
}

export enum ComplexStatusKeys {
    INACTIVE = "not_active",
    ACTIVE = "active",
    MAINTENANCE = "maintenance",
}

export class BackendEnum {
    id;
    slug;
    title;
    constructor({id = null, slug = "", title = ""}) {
        this.id = id;
        this.slug = slug;
        this.title = title;
    }

    asDropdownOption() {
        return {
            label: this.title,
            value: this.id,
        };
    }
}

export const COMPLEX_ACTIVE_STATUSES = [
    new BackendEnum({
        id: 0,
        slug: "not_active",
        title: "Inactive",
    }),
    new BackendEnum({
        id: 1,
        slug: "active",
        title: "Active",
    }),
    new BackendEnum({
        id: 2,
        slug: "maintenance",
        title: "Maintenance",
    }),
];
