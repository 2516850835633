import React, {useState} from "react";
import "react-table-6/react-table.css";
import ReactTable from "react-table-6";
import checkboxHOC from "react-table-6/lib/hoc/selectTable";
// important: this must be placed after react-table css import
import "react-table-hoc-fixed-columns/lib/styles.css";

import styles from "./SelectableTable.module.scss";

export function SelectableTable({
    data,
    columns,
    selectedItems = [],
    updateSelectedItems = undefined,
    action = undefined,
    pageSizeMax = undefined,
    thCustomStyle = undefined,
    tdCustomStyle = (state, rowInfo, column, instance) => {
        return {
            style: {
                textAlign: "left",
                wordWrap: "break-word",
                padding: "13px 8px",
                color: "#2C3439",
                fontSize: "14px"
            }
        };
    },
    tableStyle = undefined,
    filterCustomStyle = {},
    hideCheck = false,
    groupCustomStyle = (state, rowInfo, column) => {}
}) {
    const [selectAll, setSelectAll] = useState(false);

    const thDefaultStyle = {
        textAlign: "left",
        background: "#003349",
        color: "#FFFFFF",
        padding: "8px",
        fontSize: "14px"
    };

    const thStyle = {...thDefaultStyle, ...thCustomStyle};
    const CheckboxTable = !hideCheck ? checkboxHOC(ReactTable) : ReactTable;
    let data2 = [...data];
    const pageSize = data.length < pageSizeMax ? data.length + 1 : pageSizeMax;
    const showPagination = data.length > pageSizeMax;

    const toggleSelection = (key, shift, row) => {
        const formattedKey = key.replace("select-", "");

        // start off with the existing state
        let updatedSelections = [...selectedItems];
        const keyIndex = updatedSelections.indexOf(formattedKey);

        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            updatedSelections = [...updatedSelections.slice(0, keyIndex), ...updatedSelections.slice(keyIndex + 1)];
        } else {
            // it does not exist so add it
            updatedSelections.push(formattedKey);
        }
        // update the state
        updateSelectedItems(updatedSelections);
    };

    const toggleAll = () => {
        const allCheckBoxesSelected = !selectAll;
        setSelectAll(allCheckBoxesSelected);
        const selection = [];
        if (allCheckBoxesSelected) {
            data2.forEach(item => {
                selection.push(item._id);
            });
        }
        // update the state
        updateSelectedItems(selection);
    };

    const isSelected = key => {
        return selectedItems.includes(key);
    };

    const checkboxProps = {
        // Boolean to add the tick to the header checkbox.
        selectAll: selectAll,
        // Checks if each row in the table has
        // been selected on component rendering and add/remove the tick
        // depending on the boolean.
        isSelected,
        selectType: "checkbox",
        toggleSelection,
        toggleAll
    };

    return (
        <div className={styles.tableContainer}>
            <CheckboxTable
                showPaginationBottom={showPagination}
                defaultPageSize={pageSize}
                resizable={true}
                className="-striped -highlight"
                columns={columns}
                data={data}
                style={tableStyle}
                minRows={0}
                noDataText="no data found"
                getTheadThProps={() => {
                    return {
                        style: thStyle
                    };
                }}
                getTdProps={tdCustomStyle}
                getTheadFilterProps={() => {
                    return {
                        style: filterCustomStyle
                    };
                }}
                getTrGroupProps={groupCustomStyle}
                defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    return row[id] !== undefined
                        ? String(row[id])
                              .toLowerCase()
                              .startsWith(filter.value.toLowerCase())
                        : true;
                }}
                {...checkboxProps}
            />
        </div>
    );
}
