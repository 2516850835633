import classNames from "classnames";
/* 
This is mostly a convenience class to make handling component themes less verbose.
You instantiate it with the default styles and the theme to override with, and then request each class
from the instance and it will give you the properly merged version. 
Originally I wanted to generate a final theme and then just pull the classes off that, but in some cases
both themes are missing a particular class definition even if the component can use it, in which case
we don't know beforehand what all the posisble classes are.
*/
export default class ComponentTheme {
    defaultStyles: any;
    theme: any;

    constructor(defaultStyles, theme = null) {
        this.defaultStyles = defaultStyles;
        this.theme = theme;

        //Instead of actually returning this object, we return a proxy which points to the object.
        //This lets us use a dynamic getter to return the style property directly (theme.className)
        //in addition to the theme.getStyle(className) syntax.
        const handler = {
            get(target, propKey, receiver) {
                const targetValue = Reflect.get(target, propKey, receiver);

                if (typeof targetValue === "function") {
                    //A js function call is technically obj.functionName.call(), which is a GET and then an APPLY.
                    //If the consumer is trying to get a function with this GET, return a new function which calls the one they are looking for.
                    //This effectively forwards any function calls to the proxy target.
                    return function (...args) {
                        return targetValue.apply(target, args); // (A)
                    };
                } else {
                    //Otherwise we assume the consumer is accessing a style property and return the correct one.
                    return target.getStyle(propKey);
                }
            },
        };

        return new Proxy(this, handler);
    }

    getStyle(className) {
        if (this.theme === null) {
            return this.defaultStyles[className];
        }

        return classNames(this.defaultStyles[className], this.theme[className]);
    }
}
