import {AppService} from "@common/units-api";
import {Configuration} from "@common/configuration";

export class ComplexService extends AppService {
    endpoint: string;

    constructor(token: string) {
        super(token);
        this.endpoint = Configuration.unitsServiceConfig().api;
    }
    public async deleteComplexUnitsRelationships(units: Array<any>, complexId: number): Promise<any> {
        const clusterUnits = units.map((unit) => {
            return {type: "unit", id: unit.id};
        });

        const response = await this.httpService.delete(`${this.endpoint}/complexes/${complexId}/relationships/units`, {
            headers: this.getHeaders(),
            data: {data: clusterUnits},
        });
        return response.data.data;
    }

    public async getComplexes(): Promise<Array<any>> {
        const response = await this.httpService.get(`${this.endpoint}/complexes`, {
            headers: this.getHeaders(),
            params: {
                "page[size]": "1000",
            },
        });

        return response.data.data;
    }

    public async getComplex(complexId: number): Promise<any> {
        const response = await this.httpService.get(`${this.endpoint}/complexes/${complexId}`, {
            headers: this.getHeaders(),
            params: {
                "page[size]": "1000",
                include: "units,clusters",
            },
        });
        return response.data.data;
    }

    public async getComplexData(complexId: number): Promise<any> {
        const response = await this.httpService.get(`${this.endpoint}/complexes/${complexId}`, {
            headers: this.getHeaders(),
            params: {
                "page[size]": "1000",
                include: "units,clusters",
            },
        });
        return response.data;
    }

    public async createComplex(complex: object): Promise<any> {
        const response = await this.httpService.post(
            `${this.endpoint}/complexes`,
            {
                data: {
                    type: "complex",
                    attributes: {
                        ...complex,
                    },
                },
            },
            {headers: this.getHeaders()}
        );
        return response.data.data;
    }

    public async createComplexClusterRelationships(complexId: number, clusterId: number): Promise<any> {
        const response = await this.httpService.post(
            `${this.endpoint}/complexes/${complexId}/relationships/clusters`,
            {
                data: [
                    {
                        type: "cluster",
                        id: clusterId,
                    },
                ],
            },
            {headers: this.getHeaders()}
        );
        return response.data.data;
    }

    public async updateComplex(complex: any): Promise<any> {
        const response = await this.httpService.patch(
            `${this.endpoint}/complexes/${complex?.id}`,
            {
                data: {
                    type: "complex",
                    attributes: {
                        ...complex,
                    },
                },
            },
            {
                headers: this.getHeaders(),
            }
        );
        return response.data.data;
    }

    public async createComplexCluster(clusters: Array<any>, complexId: number): Promise<any> {
        const data = clusters.map((unit) => {
            return {type: "cluster", id: unit};
        });

        const response = await this.httpService.post(
            `${this.endpoint}/complexes/${complexId}/relationships/clusters`,
            {
                data,
            },
            {
                headers: this.getHeaders(),
            }
        );
        return response.data.data;
    }

    public async getComplexUnits(complexId: number): Promise<Array<any>> {
        const response = await this.httpService.get(`${this.endpoint}/complexes/${complexId}`, {
            headers: this.getHeaders(),
            params: {
                include: "units,clusters",
                "page[size]": 500,
            },
        });

        return response.data;
    }

    public async createComplexUnits(units: Array<any>, complexId: number): Promise<any> {
        const data = units.map((unit) => {
            return {type: "unit", id: unit};
        });

        const response = await this.httpService.post(
            `${this.endpoint}/complexes/${complexId}/relationships/units`,
            {
                data,
            },
            {
                headers: this.getHeaders(),
            }
        );

        return response.data.data;
    }

    public async removeComplexUnits(units: Array<any>, complexId: number) {
        const data = units.map((unit) => {
            return {type: "unit", id: unit};
        });

        const response = await this.httpService.delete(`${this.endpoint}/complexes/${complexId}/relationships/units`, {
            headers: this.getHeaders(),
            data: {data},
        });

        return response.data.data;
    }
}
