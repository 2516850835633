import React from "react";
import styles from "./AlertMessageComponent.module.scss";
import { AlertOctagon, AlertTriangle, AlertCircle, CheckCircle } from 'react-feather';

interface AlertMessageInput {
    text: string;
    className: string;
}

export const AlertMessageComponent: React.FunctionComponent<AlertMessageInput> = (props) => {

    const {text, className} = props;
    let boxIcon;

    const setIcon = () => {
        switch(className) {
            case "error":{
                boxIcon = (<AlertOctagon size="24"/>);
                break;
            }
            case "warning":{
                boxIcon = (<AlertTriangle size="24"/>);
                break;
            }
            case "info":{
                boxIcon = (<AlertCircle size="24"/>);
                break;
            }
            case "success":{
                boxIcon = (<CheckCircle size="24"/>);
                break;
            }
        }
    }

    setIcon();

    return (
        <div className={`${styles.messageBox} ${styles[className]} form-row`}>
            <span>{boxIcon}
            <span className={styles.alertText}>{text}</span>
            </span>
        </div>

    )

}