import React from "react";
import styles from "./Tab.module.scss";
import classNames from "classnames";
import ComponentTheme from "../../../styles/componentTheme";

export default function Tab({onClick, disabled = false, pending = false, isSelected = false, width = "auto", label, theme = null}) {
    const finalTheme = new ComponentTheme(styles, theme);
    const selectedStyle = isSelected ? finalTheme.getStyle("selected") : "";

    return (
        <button style={{width: width}} className={classNames(finalTheme.getStyle("default"), selectedStyle)} onClick={onClick} disabled={disabled}>
            {label}
        </button>
    );
}
