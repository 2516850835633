import React from "react";
import _ from "lodash";
import {MenuDropdown} from "../components/widget";
import {Link} from "react-router-dom";
import {Validation} from "@common/utils";

export class TableUtil {
    public static getActionMenu = (unit, menuActions) => {
        const clonedActions = _.cloneDeep(menuActions);
        for (let action of clonedActions) {
            action.args = unit;
        }
        return <MenuDropdown items={clonedActions} disabledTitle="You do not have permission to trigger this action." enabledTitle="Action Menu" />;
    };

    public static getStatus = object => {
        let status = "";

        if (object.attributes.termination_status === "terminated") {
            status = "Terminated";
        } else if (object.attributes.active_status == "maintenance") {
            status = "Maintenance";
        } else if (object.attributes.active_status === "not_active") {
            status = "Inactive";
        } else {
            status = "Active";
        }
        if (object.isRepresentative) {
            status += " (Rep)";
        }
        return status;
    };

    public static getFormattedCluster = (complexID, clusters, menuActions) => {
        return clusters.map(cluster => {
            const actionMenu = TableUtil.getActionMenu(cluster, menuActions);
            return {
                _id: cluster.id,
                id: (
                    <Link
                        to={{
                            pathname: `/addCluster/${complexID}/${cluster.id}`,
                            state: {selectedUnits: [], complexId: complexID, clusterId: cluster.id}
                        }}
                    >
                        {cluster.id}
                    </Link>
                ),
                clusterImage: <img width="150px" height="100px" src={TableUtil.getClusterImage(cluster)} />,
                unitsCount: _.get(cluster, "relationships.units.data", []).length,
                name: cluster.attributes.name,
                internalNotes: cluster.attributes.internal_notes,
                status: TableUtil.getStatus(cluster),
                rawUCluster: {...cluster},
                actions: <div style={{float: "left"}}>{actionMenu}</div>
            };
        });
    };

    public static getClusterImage = cluster => {
        const noFoundImage = `https://www-vacasa.imgix.net/house_image.png?auto=format%2Ccompress&q=45&s=7099fce03f578749921f5e542c39c979`;
        const primaryId = _.get(cluster, "attributes.rep_unit_data.attributes.primary_image_id", undefined);
        if (primaryId) {
            return `https://vacasa-units.imgix.net/${primaryId}.jpg?w=150`;
        }
        const imagesValues = _.values(_.get(cluster, "attributes.rep_unit_data.attributes.images", []));
        if (imagesValues.length == 0) {
            return noFoundImage;
        }

        return `${imagesValues[0]}?w=150`;
    };

    public static getClusterColumns = () => {
        return [
            {
                Header: "",
                accessor: "clusterImage",
                width: 150,
                sortable: false
            },
            {
                Header: "Cluster ID",
                accessor: "id",
                width: 100,
                filterable: true,
                sortable: true,
                filterMethod: (filter, row) => {
                    return row._original._id.toString().startsWith(filter.value);
                }
            },
            {
                Header: "# Units",
                accessor: "unitsCount",
                width: 150,
                filterable: true,
                sortable: true
            },
            {
                Header: "Cluster Name",
                accessor: "name",
                filterable: true,
                sortable: true,
                filterMethod: (filter, row) => {
                    return row.name
                        .toString()
                        .toLowerCase()
                        .includes(filter.value.toLowerCase());
                }
            },
            {
                Header: "Cluster Description",
                width: 200,
                accessor: "internalNotes",
                filterable: true,
                sortable: true,
                filterMethod: (filter, row) => {
                    return row.internalNotes
                        ?.toString()
                        .toLowerCase()
                        .includes(filter.value.toLowerCase());
                }
            },
            {
                Header: "Status",
                accessor: "status",
                sortable: true,
                filterable: true,
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    return row[filter.id].toLowerCase() === filter.value;
                },
                Filter: ({filter, onChange}) => (
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{width: "100%", height: "30px"}}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                        <option value="maintenance">Maintenance</option>
                    </select>
                )
            },
            {
                Header: "",
                accessor: "actions",
                fixed: "right",
                style: {
                    overflow: "visible",
                    borderLeft: "none"
                },
                sortable: false
            }
        ];
    };

    public static getUnitsTableColumns = () => {
        return [
            {
                Header: "",
                accessor: "unitImage",
                width: 150,
                sortable: false
            },
            {
                Header: "Unit ID",
                accessor: "unitID",
                width: 100,
                filterable: true,
                sortable: true
            },
            {
                Header: "Unit Code",
                accessor: "unitCode",
                width: 100,
                filterable: true,
                sortable: true,
                filterMethod: (filter, row) => {
                    return row._original.rawUnit.attributes.unit_code
                        .toString()
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase());
                }
            },
            {
                Header: "Unit Name",
                accessor: "unitName",
                filterable: true,
                sortable: true,
                width: 300,
                filterMethod: (filter, row) => {
                    return row.unitName
                        ?.toString()
                        .toLowerCase()
                        .includes(filter.value.toLowerCase());
                }
            },
            {
                Header: "Address",
                accessor: "address",
                filterable: true,
                sortable: true,
                width: 300,
                filterMethod: (filter, row) => {
                    return row.address
                        .toString()
                        .toLowerCase()
                        .includes(filter.value.toLowerCase());
                }
            },
            {
                Header: "Status",
                accessor: "status",
                sortable: true,
                filterable: true,
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }

                    console.debug(row);
                    return row[filter.id].toLowerCase() === filter.value;
                },
                Filter: ({filter, onChange}) => (
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{width: "100%", height: "30px"}}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                        <option value="terminated">Terminated</option>
                    </select>
                )
            },
            {
                Header: "",
                accessor: "actions",
                fixed: "right",
                style: {
                    overflow: "visible",
                    borderLeft: "none"
                },
                sortable: false
            }
        ];
    };

    public static getFormattedUnits = (units, menuActions) => {
        const baseUrl = Validation.validateAndGetEnvVar("REACT_APP_ADMIN_URL");
        if (_.isEmpty(units)) {
            return [];
        }

        return units.map(unit => {
            const actionMenu = TableUtil.getActionMenu(unit, menuActions);
            return {
                _id: unit.id,
                uuid: unit.id,
                unitID: unit.attributes.legacy_unit_id,
                unitImage: (
                    <img
                        width="150px"
                        height="100px"
                        src={
                            unit.attributes.primary_image_id
                                ? `https://vacasa-units.imgix.net/${unit.attributes.primary_image_id}.jpg?w=150`
                                : TableUtil.getUnitImage(unit.attributes.images)
                        }
                    />
                ),
                unitCode: (
                    <a target="_blank" href={baseUrl + "/admin/dashboard/units/edit?UnitID=" + unit.attributes.legacy_unit_id}>
                        {unit.attributes.unit_code}
                    </a>
                ),
                unitName: unit.attributes.name,
                address: `${unit.attributes.address.address_1} ${unit.attributes.address.address_2 ? unit.attributes.address.address_2 : ""}`,
                status: TableUtil.getStatus(unit),
                rawUnit: {...unit},
                actions: <div style={{float: "left"}}>{actionMenu}</div>
            };
        });
    };

    public static getUnitImage = images => {
        const noFoundImage = `https://www-vacasa.imgix.net/house_image.png?auto=format%2Ccompress&q=45&s=7099fce03f578749921f5e542c39c979`;

        if (images?.length > 0) {
            return `${images[0]}?w=150`;
        }

        return noFoundImage;
    };
}
