import * as React from "react";
import {ComplexHeader} from "./complexHeader/ComplexHeader";
import {ComplexTable} from "./complexTable/ComplexTable";
import {Can} from "../../../permissions/Can";
import {Ability} from "../../../permissions/Ability";

export const ComplexList: React.FunctionComponent<any> = () => {
    return (
        <div>
            <Can I={Ability.Action.Read} a={Ability.Entity.Complex}>
                <ComplexHeader />
                <ComplexTable />
            </Can>            
        </div>
    );
};
