import {AppService} from "@common/units-api";
import {Configuration} from "@common/configuration";
import {appToast} from "../../components/widget/AppToast/AppToast";
import * as _ from "lodash";
import {CreateClusterRequest} from "../../types/cluster.type";

export class ClusterService extends AppService {
    endpoint: string;

    constructor(token: string) {
        super(token);
        this.endpoint = Configuration.unitsServiceConfig().api;
    }

    public async getClusterWithUnits(clusterID: number): Promise<Array<any>> {
        const result = await this.httpService.get(`${this.endpoint}/clusters/${clusterID}`, {
            headers: this.getHeaders(),
            params: {
                "page[size]": "1000",
                include: "units,rep_unit",
            },
        });

        return result.data.data;
    }

    public async getClusterWithUnitsInclude(clusterID: number): Promise<Array<any>> {
        const result = await this.httpService.get(`${this.endpoint}/clusters/${clusterID}`, {
            headers: this.getHeaders(),
            params: {
                "page[size]": "1000",
                include: "units,rep_unit",
            },
        });

        return result.data;
    }

    public async getClustersWithUnits(custerIds: Array<number>): Promise<{clusters: Array<any>; units: Array<any>}> {
        const result = await this.httpService.get(`${this.endpoint}/clusters`, {
            headers: this.getHeaders(),
            params: {
                "page[size]": "1000",
                include: "units,rep_unit",
                "filter[id][in]": custerIds,
            },
        });

        return {
            clusters: result.data.data,
            units: result.data.included,
        };
    }

    handleFormError(error, defaultMessage) {
        console.error(error);
        if (_.has(error, "isHandled")) {
            return error.messages.forEach((message) => appToast.error(message));
        }

        appToast.error(defaultMessage);
    }

    public async createCluster(cluster: any, repUnit?: any, units?: Array<any>): Promise<any> {
        let data: CreateClusterRequest = {
            type: "cluster",
            attributes: {
                ...cluster,
            },
        };

        if (_.isEmpty(units) && cluster.active_status != "active") {
            const result = await this.httpService.post(`${this.endpoint}/clusters`, {data}, {headers: this.getHeaders()});
            return result.data.data;
        }

        if (!_.isEmpty(units) && _.isNil(repUnit)) {
            this.handleFormError({}, "To add units at least one must be representative");
            throw new Error();
        }

        if (!_.isNil(repUnit)) {
            data = {
                ...data,
                relationships: {
                    ...data.relationships,
                    rep_unit: {
                        data: {
                            type: "unit",
                            id: repUnit.id,
                        },
                    },
                },
            };
        }

        if (!_.isEmpty(units)) {
            data = {
                ...data,
                relationships: {
                    ...data.relationships,
                    units: {
                        data: units.map((x) => {
                            return {
                                type: "unit",
                                id: x.id,
                            };
                        }),
                    },
                },
            };
        }

        const result = await this.httpService.post(`${this.endpoint}/clusters`, {data}, {headers: this.getHeaders()});
        return result.data.data;
    }

    public async updateCluster(cluster: object, clusterId: number): Promise<any> {
        const data = {
            type: "cluster",
            attributes: {
                ...cluster,
            },
        };

        const result = await this.httpService.patch(
            `${this.endpoint}/clusters/${clusterId}`,
            {
                data,
            },
            {headers: this.getHeaders()}
        );
        return result.data.data;
    }

    public async createClusterUnits(units: Array<any>, clusterId: number): Promise<any> {
        const data = units.map((unit) => {
            return {type: "unit", id: unit.id};
        });
        const result = await this.httpService.post(
            `${this.endpoint}/clusters/${clusterId}/relationships/units`,
            {
                data,
            },
            {headers: this.getHeaders()}
        );
        return result.data.data;
    }

    public async updateClusterRepUnit(clusterId: number, repUnitId: string): Promise<any> {
        const result = await this.httpService.patch(
            `${this.endpoint}/clusters/${clusterId}/relationships/rep-unit`,
            {
                data: {type: "unit", id: repUnitId},
            },
            {headers: this.getHeaders()}
        );
        return result.data.data;
    }

    public async getClusterRepUnit(clusterId: number): Promise<any> {
        const result = await this.httpService.get(`${this.endpoint}/clusters/${clusterId}/relationships/rep-unit`, {
            headers: this.getHeaders(),
        });
        return result.data.data;
    }

    public async deleteClusterRepUnit(clusterId, repUnitId): Promise<any> {
        const result = await this.httpService.delete(`${this.endpoint}/clusters/${clusterId}/relationships/rep-unit`, {
            headers: this.getHeaders(),
            data: {data: {type: "unit", id: repUnitId}},
        });
        return result.data.data;
    }

    public async addClusterRepUnit(clusterId: number, repUnitId: string): Promise<any> {
        const result = await this.httpService.post(`${this.endpoint}/clusters/${clusterId}/relationships/rep-unit`, {
            headers: this.getHeaders(),
            data: {data: {type: "unit", id: repUnitId}},
        });
        return result.data.data;
    }

    public async removeClusterUnits(units: Array<any>, clusterId: number): Promise<any> {
        const data = units.map((unit) => {
            return {type: "unit", id: unit};
        });

        const result = await this.httpService.delete(`${this.endpoint}/clusters/${clusterId}/relationships/units`, {
            headers: this.getHeaders(),
            data: {data},
        });
        return result.data.data;
    }

    public async addUnitToCluster(units: Array<any>, clusterId: number): Promise<any> {
        const data = units.map((unit) => {
            return {type: "unit", id: unit};
        });

        const result = await this.httpService.post(
            `${this.endpoint}/clusters/${clusterId}/relationships/units`,
            {
                data,
            },
            {headers: this.getHeaders()}
        );
        return result.data.data;
    }

    public async deleteCluster(clusterId: number): Promise<any> {
        const result = await this.httpService.delete(`${this.endpoint}/clusters/${clusterId}`, {
            headers: this.getHeaders(),
        });
        return result.data.data;
    }
}
