import React, {useEffect, useState} from "react";
import {KeyLevelUnitTable} from "./keyLevelUnitTable/KeyLevelUnitTable";
import {ClusterService} from "../../../../services/units-api/cluster.service";
import {ClusterTable} from "./clusterTable/ClusterTable";
import {Loader} from "../../../widget";
import {TableHeader} from "./tableHeader/TableHeader";
import {ConfirmationModal} from "../../../widget/ConfirmationModal/ConfirmationModal";
import {ImageService} from "../../../../services/units-api/image.service";
import {appToast} from "../../../widget/AppToast/AppToast";
import {authManager} from "@common/authentication";
import {ComplexService} from "../../../../services/units-api/complex.service";
import _ from "lodash";

interface UnitAndClusterTabProps {
    complexId: number;
    setComplex: any;
}

export const UnitAndClusterTab: React.FunctionComponent<UnitAndClusterTabProps> = (props) => {
    const [clusterWithUnits, setClusterWithUnits] = useState(null);
    const [keyLevelUnits, setKeyLevelUnits] = useState(null);
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [clusterToDelete, setClusterToDelete] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [reloadView, setReloadView] = useState(false);

    const {complexId, setComplex} = props;

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const clusterUnits = [];
                const clusters = [];

                setLoadingMessage("Getting units for complex.");
                const complexService = new ComplexService(authManager.getJwt());
                const response = (await complexService.getComplexUnits(complexId)) as any;
                setComplex(response.data);

                let complexUnits = _.get(response, "included", []).filter((x) => x.type == "unit");

                setLoadingMessage("Getting units images.");
                const imageService = new ImageService(authManager.getJwt());
                complexUnits = await imageService.addImagesToUnits(complexUnits);

                const complexClusters = _.get(response, "data.relationships.clusters.data", []).filter((x) => x.type == "cluster");

                if (complexClusters && complexClusters.length > 0) {
                    setLoadingMessage(`Getting clusters for complex.`);
                    const clusterService = new ClusterService(authManager.getJwt());
                    const clusterWithUnitsResult = await clusterService.getClustersWithUnits(complexClusters.map((x) => x.id).join(","));
                    setLoadingMessage("Getting cluster images.");
                    clusterWithUnitsResult.units = await imageService.addImagesToUnits(clusterWithUnitsResult.units);
                    setLoadingMessage(`Preparing data.`);

                    for (let cluster of clusterWithUnitsResult.clusters) {
                        const repUnit = getRepUnit(cluster, complexUnits, clusterWithUnitsResult.units);

                        if (!_.isNil(repUnit)) {
                            clusterUnits.push(repUnit, ..._.get(cluster, "relationships.units.data", []));
                            cluster.attributes.rep_unit_data = repUnit;
                        }

                        clusters.push(cluster);
                    }
                }

                setClusterWithUnits(clusters);
                setKeyLevelUnits(_.differenceBy(complexUnits, clusterUnits, (x) => x.id));
                setIsLoading(false);
            } catch (e) {
                appToast.error("Error getting unit and cluster");
                console.error(e);
                setIsLoading(false);
            }
        })();
    }, [reloadView]);

    const handleRemoveCluster = (cluster: any) => {
        setClusterToDelete(cluster);
        setShowConfirmationModal(true);
    };

    const triggerReload = () => {
        setReloadView(!reloadView);
    };

    const handleConfirmationModal = async () => {
        try {
            setShowConfirmationModal(false);
            setIsLoading(true);
            const unitsCluster = _.get(clusterToDelete, "relationships.units.data", undefined);
            const clusterService = new ClusterService(authManager.getJwt());
            await clusterService.deleteCluster(clusterToDelete.id);
            const complexService = new ComplexService(authManager.getJwt());
            if (unitsCluster) {
                await complexService.createComplexUnits(
                    unitsCluster.map((x) => x.id),
                    complexId
                );
            }

            appToast.success("Cluster deleted successfully!");
            setClusterToDelete(null);
            setIsLoading(false);
            triggerReload();
        } catch (error) {
            console.error(error);
            appToast.error("Error deleting Cluster");
        }
    };

    const handleCloseModal = () => {
        setShowConfirmationModal(false);
    };

    const getRepUnit = (cluster, complexUnits, unitsCluster) => {
        const repUnitId = _.get(cluster, "relationships.rep_unit.data.id", null);
        return repUnitId
            ? {
                  ...cluster.relationships.rep_unit.data,
                  ...complexUnits.find((x) => x.id === repUnitId),
                  ...unitsCluster.find((x) => x.id === repUnitId),
              }
            : null;
    };

    if (isLoading) {
        return <Loader size="14px" message={loadingMessage} />;
    }

    return (
        <div>
            <TableHeader
                complexId={complexId}
                selectedUnits={selectedUnits}
                setSelectedUnits={setSelectedUnits}
                clusters={clusterWithUnits}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                updateReloadComponent={null}
                reloadComponent={null}
                setReloadView={setReloadView}
                reloadView={reloadView}
                triggerReload={triggerReload}
                unitsList={keyLevelUnits}
            />
            <ClusterTable complexId={complexId} clusters={clusterWithUnits} onClusterRemove={handleRemoveCluster} />
            <KeyLevelUnitTable
                complexId={complexId}
                units={keyLevelUnits}
                selectedUnits={selectedUnits}
                setSelectedUnits={setSelectedUnits}
                triggerReload={triggerReload}
            />
            <ConfirmationModal
                title="Deleting a cluster cannot be undone"
                body="Once deleted, units within the cluster will be reverted to key-level units at the complex level"
                confirmButton="Yes, Delete"
                show={showConfirmationModal}
                handleClose={handleCloseModal}
                handleConfirmation={handleConfirmationModal}
            />
        </div>
    );
};
