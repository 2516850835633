import * as React from "react";
import "./Loader.css";

export function Loader({ size, className = "", message = "" }) {
  const bounceStyle = {
    width: size,
    height: size,
    borderRadius: size,
    message: message,
  };

  const createBounceDivs = (num) => {
    let divs = [];

    for (let i = 0; i < num; i++) {
      divs.push(<div key={i} className={"bounce" + i} style={bounceStyle} />);
    }

    return divs;
  };

  return (
    <div style={{ textAlign: "center" }} className={`loader-dots ${className}`}>
      {createBounceDivs(3).map((div) => div)}
      <p>{message}</p>
    </div>
  );
}
