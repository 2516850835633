import * as React from "react";
import styles from "./ComplexManage.module.scss";
import {Link, useLocation} from "react-router-dom";
import {ComplexForm} from "./complexForm/ComplexForm";
import {IconBackArrow} from "../../../assets/icons";

export const ComplexManage: React.FunctionComponent<any> = () => {
    const location = useLocation();
    const complexId = location.state ? location.state["complexId"] : null;
    const backArrowIconStyleSettings = {
        height: "24px",
        width: "24px",
        fill: "currentColor",
        marginLeft: "8px"
    };

    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.headerWrapper}>
                    <div className={styles.backLink}>
                        <Link className="defaultBackLink" to="/searchComplex">
                            <IconBackArrow style={backArrowIconStyleSettings} />
                            Search for a Complex
                        </Link>
                    </div>
                </div>
            </div>
            <ComplexForm complexId={complexId} onFormChange={() => {}} />
        </React.Fragment>
    );
};
