import * as React from "react";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {UnitAdd} from "../components/unit/unitAdd/UnitAdd";
import {ComplexList} from "../components/complex/complexList/ComplexList";
import {ComplexManage} from "../components/complex/complexManage/ComplexManage";
import {ComplexTab} from "../components/complex/complexTab/ComplexTab";
import {ClusterAdd} from "../components/cluster/clusterAdd/ClusterAdd";
import {ClusterAddUnit} from "../components/cluster/clusterAddUnit/ClusterAddUnit";

class RouterApp extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/complexManage" component={ComplexManage} />
                    <Route path="/complexTab/:id" component={ComplexTab} />
                    <Route path="/addUnits/:complexID" component={UnitAdd} />
                    <Route exact path="/addCluster/:complexID" component={ClusterAdd} />
                    <Route exact path="/addCluster/:complexID/:clusterID" component={ClusterAdd} />
                    <Route path="/addClusterUnit/:complexID/:clusterID" component={ClusterAddUnit} />
                    <Route path="/" component={ComplexList} />
                </Switch>
            </Router>
        );
    }
}

export default RouterApp;
