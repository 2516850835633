import React from "react";
import {Link} from "react-router-dom";
import styles from "./UnitAddHeader.module.scss";
import {Button} from "../../../widget";
import {IconBackArrow} from "../../../../assets/icons";

interface UnitAddHeaderProps {
    selectedUnitsLength: number;
    showSearchResults: boolean;
    handleAddUnits: Function;
    isAddComplexUnitPending: boolean;
    complexID: number;
    clusterID: number | undefined;
    availableToAdd?: boolean;
}

export const UnitAddHeader: React.FunctionComponent<UnitAddHeaderProps> = props => {
    const generateBackUrl = () => {
        if (props.clusterID) {
            return `/addCluster/${props.complexID}/${props.clusterID}`;
        }

        return `/complexTab/${props.complexID}`;
    };

    const backStyle = {
        height: "16px",
        width: "16px",
        fill: "currentColor"
    };
    return (
        <React.Fragment>
            <div className={styles.headerWrapper}>
                <div className={styles.backToSearchLink}>
                    <Link
                        to={{
                            pathname: generateBackUrl(),
                            state: {complexId: props.complexID}
                        }}
                    >
                        <IconBackArrow style={backStyle} onClick={null} />
                    </Link>
                    <span>Add Units</span>
                </div>
                {props.showSearchResults ? (
                    <Button
                        type="primary"
                        disabled={props.availableToAdd ? props.availableToAdd : props.selectedUnitsLength < 1 }
                        width={"128px"}
                        onClick={props.handleAddUnits}
                        pending={props.isAddComplexUnitPending}
                    >
                        Add Units
                    </Button>
                ) : null}
            </div>
        </React.Fragment>
    );
};
