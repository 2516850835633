import React from "react";
import ReactDOM from "react-dom";
import * as _ from "lodash";
import {authManager, ConnectToServices} from "@common/authentication";
import App from "./app/App";

const params = new URLSearchParams(window.location.search);

let token: string = params.get("token");
if (_.isEmpty(token)) {
    token = authManager.getTokenFromAdmin();
}

ReactDOM.render(
    <React.StrictMode>
        <ConnectToServices tokenFromAdmin={token}>
            <App />
        </ConnectToServices>
    </React.StrictMode>,
    document.getElementById("root")
);
