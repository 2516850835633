import React, {useState, useEffect} from "react";
import * as _ from "lodash";
import {UnitsService} from "@common/units-api";
import {useHistory, RouteChildrenProps} from "react-router-dom";
import {UnitAddHeader} from "../../unit/unitAdd/unitAddHeader/UnitAddHeader";
import {UnitAddSearchForm} from "../../unit/unitAdd/unitAddSearchForm/UnitAddSearchForm";
import {UnitAddList} from "../../unit/unitAdd/unitAddList/UnitAddList";
import {ClusterService} from "../../../services/units-api/cluster.service";
import {appToast} from "../../widget/AppToast/AppToast";
import {authManager} from "@common/authentication";

export const ClusterAddUnit: React.FunctionComponent<RouteChildrenProps<{clusterID; complexID}>> = props => {
    const [isSearchPending, setIsSearchPending] = useState(false);
    const [isAddComplexUnitPending, setIsAddComplexUnitPending] = useState(false);
    const [showSearchForm, setShowSearchForm] = useState(true);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [searchUnits, setSearchUnits] = useState([]);
    const [repUnit, setRepUnit] = useState(null);
    const [availableToAdd, setAvailableToAdd] = useState(true);
    const [unitMsg, setUnitMsg] = useState(null);

    const clusterID = props.match.params.clusterID;
    const complexID = props.match.params.complexID;
    const history = useHistory();

    const checkComplexID = async () => {
        if (!complexID || !clusterID) {
            history.push("/searchComplex");
        }

        let localCluster;
        const clusterService = new ClusterService(authManager.getJwt());
        localCluster = await clusterService.getClusterWithUnitsInclude(Number(clusterID));

        let localUnits = _.uniqBy(localCluster.included, "id");

        const representativeUnit = localCluster.data.relationships.rep_unit.data;

        const found = localUnits.find((x: any) => x.id === representativeUnit?.id) as any;
        if (found) {
            found.isRepresentative = true;
            setRepUnit(found);
        }
    };

    useEffect( () => {checkComplexID()}, [availableToAdd]);

    const handleFormError = (error, defaultMessage) => {
        console.error(error);
        if (_.has(error, "isHandled")) {
            for (let errorMessage of error.messages) {
                appToast.error(errorMessage);
            }
            return;
        }
        appToast.error(defaultMessage);
    };

    const handleUnitSearchEvent = async (searchData: {[key: string]: any}) => {
        try {
            setIsSearchPending(true);
            setShowSearchResults(false);
            const unitService = new UnitsService(authManager.getJwt());
            const response = await unitService.searchUnits(searchData);
            setSearchUnits(response);
            setAvailableToAdd(true);
        } catch (e) {
            appToast.error(e.toString());
        } finally {
            setIsSearchPending(false);
            setShowSearchResults(true);
        }
    };

    const toggleSearchForm = () => {
        showSearchForm ? setShowSearchForm(false) : setShowSearchForm(true);
    };

    const validateMaxOccupancy = (unitSelected) => {
        if (repUnit === null)
            return true;
        return repUnit.attributes.max_occupancy <= unitSelected.attributes.max_occupancy;
    }

    const validateUnitSelection = (unitSelected) => {
        const unitData = searchUnits.find(unit => {return unit.id === unitSelected})
        return validateMaxOccupancy(unitData);
    }

    const handleUnitSelection = updatedSelectedUnits => {
        setAvailableToAdd(true);
        if (updatedSelectedUnits.length > 0) {
           if (validateUnitSelection(updatedSelectedUnits[updatedSelectedUnits.length - 1]))
                setSelectedUnits(updatedSelectedUnits);
            else {
               setUnitMsg(repUnit?.attributes?.legacy_unit_id);
               setAvailableToAdd(false);
           }
        } else setSelectedUnits(updatedSelectedUnits);
    };

    const handleAddUnits = async () => {
        try {
            setIsAddComplexUnitPending(true);
            const mappedUnits = selectedUnits.map(unitId => {
                return {id: unitId};
            });

            const clusterService = new ClusterService(authManager.getJwt());
            await clusterService.createClusterUnits(mappedUnits, clusterID);
            const unitString = selectedUnits.length > 1 ? "units" : "unit";
            appToast.success(`Added ${selectedUnits.length} ${unitString} to cluster.`);
            history.push(`/addCluster/${complexID}/${clusterID}`);
        } catch (error) {
            handleFormError(error, "Error adding units");
        } finally {
            setIsAddComplexUnitPending(false);
        }
    };

    return (
        <React.Fragment>
            <UnitAddHeader
                complexID={complexID}
                selectedUnitsLength={selectedUnits.length}
                showSearchResults={showSearchResults}
                handleAddUnits={handleAddUnits}
                isAddComplexUnitPending={isAddComplexUnitPending}
                clusterID={clusterID}
                availableToAdd={!availableToAdd}
            />
            <UnitAddSearchForm handleUnitSearchEvent={handleUnitSearchEvent} showSearchForm={showSearchForm} isSearchPending={isSearchPending} availableToAdd={availableToAdd} unitMsg={unitMsg}/>
            {showSearchResults ? (
                <React.Fragment>
                    <UnitAddList
                        units={searchUnits}
                        selectedUnits={selectedUnits}
                        handleUnitSelection={handleUnitSelection}
                        toggleSearchForm={toggleSearchForm}
                        showSearchForm={showSearchForm}
                    />
                </React.Fragment>
            ) : null}
        </React.Fragment>
    );
};
