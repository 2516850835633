import React from "react";
import RouterApp from "../router/Router";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/commom.scss";
import {AbilityContext, Can} from "../permissions/Can";
import ability, {Ability} from "../permissions/Ability";

toast.configure();
class App extends React.Component {
    render() {
        return (
            <AbilityContext.Provider value={ability}>
                <Can not I={Ability.Action.Read} a={Ability.Entity.Complex}>
                    <h4>You do not have Complexes_view2 permission to display this section.</h4>
                </Can>
                <RouterApp />
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </AbilityContext.Provider>
        );
    }
}

export default App;
