import React, {useEffect, useState} from "react";
import {ClusterForm} from "./clusterForm/ClusterForm";
import {useLocation} from "react-router-dom";
import {ClusterHeader} from "./clusterHeader/ClusterHeader";
import _ from "lodash";

export const ClusterAdd: React.FunctionComponent<any> = props => {
    const location = useLocation();
    const stateUnits = _.get(location.state, "selectedUnits");
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const complexId = props.match.params.complexID;
    const clusterId = props.match.params.clusterID || "";
    const [isHeaderLoading, setIsHeaderLoading] = useState(false);
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [fetchingCluster, setFetchingCluster] = useState(false);
    const [clusterUnits, updateClusterUnits] = useState([]);
    const [reloadUnit, setReloadUnits] = useState(0);
    const [cluster, setCluster] = useState({
        id: "",
        name: "",
        status: "not_active",
        description: ""
    });

    useEffect(() => {}, []);

    return (
        <React.Fragment>
            <ClusterHeader
                complexId={complexId}
                clusterId={clusterId}
                selectedUnits={selectedUnits}
                setSelectedUnits={setSelectedUnits}
                fetchingCluster={fetchingCluster}
                setFetchingCluster={setFetchingCluster}
                submitDisabled={submitDisabled}
                setSubmitDisabled={setSubmitDisabled}
                setSubmitLoading={setSubmitLoading}
                submitLoading={submitLoading}
                clusterUnits={clusterUnits}
                updateClusterUnits={updateClusterUnits}
                cluster={cluster}
                setCluster={setCluster}
                isHeaderLoading={isHeaderLoading}
                setIsHeaderLoading={setIsHeaderLoading}
                reloadUnit={reloadUnit}
                setReloadUnits={setReloadUnits}
            />
            <ClusterForm
                stateUnits={stateUnits}
                complexID={complexId}
                clusterID={clusterId}
                selectedUnits={selectedUnits}
                setSelectedUnits={setSelectedUnits}
                fetchingCluster={fetchingCluster}
                setFetchingCluster={setFetchingCluster}
                updateFetchingCluster={() => {}}
                submitDisabled={submitDisabled}
                setSubmitDisabled={setSubmitDisabled}
                clusterUnits={clusterUnits}
                updateClusterUnits={updateClusterUnits}
                cluster={cluster}
                setCluster={setCluster}
                isHeaderLoading={isHeaderLoading}
                setIsHeaderLoading={setIsHeaderLoading}
                reloadUnit={reloadUnit}
                setReloadUnits={setReloadUnits}
            />
        </React.Fragment>
    );
};
