import React, {useState} from "react";
import styles from "../clusterTable/ClusterTable.module.scss";
import lomStyles from "./Lom.module.scss";
import {Button} from "../../../../widget";
import {ComplexService} from "../../../../../services/units-api/complex.service";
import {ClusterService} from "../../../../../services/units-api/cluster.service";
import {useHistory} from "react-router-dom";
import {ConfirmationModal} from "../../../../widget/ConfirmationModal/ConfirmationModal";
import {IconX, IconTrash} from "../../../../../assets/icons";
import _ from "lodash";
import {appToast} from "../../../../widget/AppToast/AppToast";
import {Can} from "../../../../../permissions/Can";
import { Ability } from "../../../../../permissions/Ability";
import {authManager} from "@common/authentication";

interface TableHeaderProps {
    complexId: any;
    selectedUnits: any;
    setSelectedUnits: any;
    clusters: any;
    isLoading: any;
    setIsLoading: any;
    updateReloadComponent: any;
    reloadComponent: any;
    setReloadView: any;
    reloadView: any;
    triggerReload: any;
    unitsList:any
}

export const TableHeader: React.FunctionComponent<TableHeaderProps> = props => {
    const {
        complexId,
        isLoading,
        setIsLoading,
        setSelectedUnits,
        selectedUnits,
        clusters,
        triggerReload,
        unitsList
    } = props;
    const history = useHistory();
    const [showConfirmationModal, setShowConfirmationModal] = useState({units: null, show: false});

    const validateMaxOccupancy = (unitSelected, repUnit) => {
        if (_.isNil(repUnit))
            return true;
        return repUnit.attributes.max_occupancy <= unitSelected.attributes.max_occupancy;
    }

    const moveUnitsToCluster = async (units, cluster) => {
        try {
            setIsLoading(true);
            let errorUnits = []
            units.forEach(x => {
                const unit = unitsList.find(unit => unit.id === x);
                if (!validateMaxOccupancy(unit, cluster.attributes.rep_unit_data)) {
                    errorUnits.push(unit.attributes.legacy_unit_id);
                }
            })
            if (errorUnits.length === 0) {
                const unitIds = units.map(x => {
                    return {id: x};
                });
                const complexService = new ComplexService((authManager.getJwt()));
                await complexService.deleteComplexUnitsRelationships(unitIds, complexId);
                const clusterService = new ClusterService(authManager.getJwt());
                await clusterService.createClusterUnits(unitIds, cluster.id);
                appToast.success(`Units have been successfully added to ${cluster.attributes.name}`);
                setSelectedUnits([]);
                triggerReload();
            } else {
                appToast.error(
                    `Unit(s) ${errorUnits} can not be added to cluster ${cluster.attributes.name} because max occupancy is minor that rep unit ${cluster.attributes.rep_unit_data.attributes.legacy_unit_id}. Please unselect it/them and move the rest of the selected units`
                );
                setIsLoading(false);
            }
        } catch (e) {
            console.error(e);
            appToast.error(`Error adding Units to ${cluster?.attributes?.name}`);
            setIsLoading(false);
            setSelectedUnits([]);
        }
    };

    const handleRemoveUnits = units => {
        if (!Array.isArray(units)) {
            units = [units.id];
        }

        let unitToastMessage = "Unit removed from complex.";
        let unitBodyMessage = "Once removed you will need to add the unit again if you want to undo this change.";

        if (units.length > 0) {
            unitToastMessage = "Units removed from complex.";
            unitBodyMessage = "Once removed you will need to add those units again if you want to undo this change.";
        }

        setShowConfirmationModal({units: units, show: true});
    };

    const AddUnits = () => {
        if (selectedUnits.length == 0) {
            return (
                <Can I={Ability.Action.Update} a={Ability.Entity.Complex}>
                    <Button
                        type="primary"
                        width="auto"
                        className={styles.actionBarButton}
                        onClick={() => history.push(`/addUnits/${complexId}`, {complexId: complexId})}
                        disabled={isLoading}
                    >
                        Add Units
                    </Button>
                </Can>
            );
        }
        return null;
    };

    const AddCluster = () => {
        if (isLoading) {
            return null;
        }

        return (
            <span style={{display: "inline-block", marginRight: "12px"}}>
                <Can I={Ability.Action.Update} a={Ability.Entity.Complex}>
                    <Button
                        type="primary"
                        width="auto"
                        className={styles.actionBarButton}
                        onClick={() => {
                            history.push({
                                pathname: `/addCluster/${complexId}`,
                                state: {selectedUnits, complexId}
                            });
                        }}
                    >
                        New Cluster
                    </Button>
                </Can>
            </span>
        );
    };

    const RemoveUnitButton = () => {
        if (isLoading) {
            return null;
        }

        if (selectedUnits.length > 0) {
            return (
                <Button
                    onClick={() => {
                        handleRemoveUnits(selectedUnits);
                    }}
                    width="auto"
                    className={styles.removeUnitButton}
                >
                    <IconTrash className={styles.removeUnitIcon} onClick={null} />
                    Remove
                </Button>
            );
        }
        return null;
    };

    const CancelActionButton = () => {
        if (isLoading) {
            return null;
        }
        if (selectedUnits.length > 0) {
            return (
                <Button
                    onClick={() => {
                        setSelectedUnits([]);
                    }}
                    width="auto"
                    className={styles.removeUnitButton}
                >
                    <IconX style={{width: "20px", fill: "#007eca"}} onClick={null} />
                </Button>
            );
        }
        return null;
    };

    const MoveUnitSelect = () => {
        if (isLoading) {
            return null;
        }
        if (clusters.length > 0 && selectedUnits.length > 0) {
            const clusterList = clusters.map(cluster => (
                <option value={cluster.id} key={cluster.id}>
                    {cluster.attributes.name}
                </option>
            ));
            return (
                <select
                    style={{color: "#655655", borderRadius: "0px", margin: "5px", fontSize: "15px"}}
                    className={lomStyles.lomSelect}
                    onChange={async e => {
                        const clusterId = e.target.value;
                        if (clusterId === "0") {
                            return;
                        }
                        await moveUnitsToCluster(
                            selectedUnits,
                            clusters.find(x => x.id == clusterId)
                        );
                    }}
                >
                    <option value={0} key={0}>
                        Move to
                    </option>
                    {clusterList}
                </select>
            );
        }
        return null;
    };

    const handleClose = () => {
        setShowConfirmationModal({units: null, show: false});
    };

    const handleConfirn = async () => {
        try {
            const previuosState = _.cloneDeep(showConfirmationModal);
            setShowConfirmationModal({units: null, show: false});
            const complexService = new ComplexService((authManager.getJwt()));
            await complexService.removeComplexUnits(previuosState.units, complexId);
            appToast.success("Unit removed from complex.");
            setSelectedUnits([]);
            triggerReload();
        } catch (e) {
            console.error(e);
            appToast.error("Error removing unit from complex.");
            setSelectedUnits([]);
        }
    };

    if (!clusters) {
        return null;
    }

    return (
        <React.Fragment>
            <div className={styles.actionBarWrapper}>
                <div className={styles.backToSearchLink}>
                    <Can I={Ability.Action.Update} a={Ability.Entity.Complex}>
                        <RemoveUnitButton />
                        <AddUnits />
                        <MoveUnitSelect />
                        <AddCluster />
                        <CancelActionButton />
                    </Can>
                </div>
            </div>
            <ConfirmationModal
                title="Are you sure you want to remove?"
                body="Once removed you will need to add the unit again if you want to undo this change."
                confirmButton="Remove"
                show={showConfirmationModal.show}
                handleConfirmation={handleConfirn}
                handleClose={handleClose}
            />
        </React.Fragment>
    );
};
