import React, {useEffect, useState} from "react";
import actionStyles from "./ClusterHeader.module.scss";
import Tippy from "@tippyjs/react";
import styles from "./ClusterHeader.module.scss";
import {ComplexService} from "../../../../services/units-api/complex.service";
import {Icon, Button, Loader} from "../../../widget";
import {useHistory} from "react-router-dom";
import {ClusterService} from "../../../../services/units-api/cluster.service";
import * as _ from "lodash";
import {IconBackArrow} from "../../../../assets/icons";
import {appToast} from "../../../widget/AppToast/AppToast";
import {Can} from "../../../../permissions/Can";
import { Ability } from "../../../../permissions/Ability";
import {authManager} from "@common/authentication";
import {UnitsService} from "@common/units-api/lib/src/units.service";

interface ClusterHeaderProps {
    complexId: string;
    clusterId: string;
    fetchingCluster: any;
    selectedUnits: any;
    setSelectedUnits: any;
    submitDisabled: any;
    setSubmitDisabled: any;
    submitLoading: any;
    setSubmitLoading: any;
    setFetchingCluster: any;
    clusterUnits: any;
    updateClusterUnits: any;
    cluster: any;
    setCluster: any;
    isHeaderLoading: any;
    setIsHeaderLoading: any;
    reloadUnit: any;
    setReloadUnits: any;
}
export const ClusterHeader: React.FunctionComponent<ClusterHeaderProps> = props => {
    const {
        complexId,
        clusterId,
        fetchingCluster,
        selectedUnits,
        submitDisabled,
        submitLoading,
        clusterUnits,
        cluster,
        isHeaderLoading,
        setIsHeaderLoading,
        setSelectedUnits,
        reloadUnit,
        setReloadUnits
    } = props;

    const [clusters, updateClusters] = useState([]);
    const history = useHistory();

    useEffect(() => {
        async function getClusters() {
            try {
                const complexService = new ComplexService((authManager.getJwt()));
                const complex = await complexService.getComplexData(Number(complexId));
                const clusters = _.get(complex, "included", [{type: "na"}]).filter(x => x.type == "cluster");
                updateClusters(clusters);
            } catch (e) {
                console.error("Error getting Clusters information", e);
                appToast.error("Error getting Clusters information");
            }
        }

        getClusters();
    }, []);

    const handleFormSubmit = async () => {
        if (clusterId === "") {
            await addCluster();
            return;
        }

        await updateCluster();
    };

    const addCluster = async () => {
        try {
            setIsHeaderLoading(true);
            const complexService = new ComplexService((authManager.getJwt()));
            await complexService.deleteComplexUnitsRelationships(clusterUnits, Number(complexId));
            const clusterService = new ClusterService(authManager.getJwt());
            const clusterResult = await clusterService.createCluster(
                {
                    active_status: cluster.status,
                    name: cluster.name,
                    internal_notes: cluster.description
                },
                getRepUnit(),
                clusterUnits
            );

            if (clusterResult) {
                await complexService.createComplexClusterRelationships(Number(complexId), clusterResult.id);
            }

            appToast.success("Cluster created successfully!");
            history.push(`/complexTab/${complexId}`, {complexId: complexId});
        } catch (error) {
            handleFormError(error, "Error creating cluster");
            setIsHeaderLoading(false);
        }
    };

    const updateCluster = async () => {
        try {
            setIsHeaderLoading(true);
            const clusterService = new ClusterService(authManager.getJwt());
            if (cluster.status == "active") {
                let repUnit = getRepUnit();
                if (!_.isNil(repUnit)) {
                    let errorUnits = [];
                    clusterUnits.forEach(unit => {
                        if (!validateMaxOccupancy(unit, repUnit)) {
                            errorUnits.push(`, unit id ${unit.attributes.legacy_unit_id}`);
                        }
                    });
                    if (errorUnits.length > 0 ) {
                        handleFormError({}, `Can not activate this cluster because has a Rep unit with max occupancy  that is greater than the max occupancy of one or more cluster units${errorUnits}. The max occupancy must be changed or a different unit must be selected as the Rep Unit.`);
                        return;
                    } else {
                        await clusterService.updateCluster(
                            {
                                active_status: cluster.status,
                                name: cluster.name,
                                internal_notes: cluster.description
                            },
                            cluster.id
                        );
                        await clusterService.updateClusterRepUnit(cluster.id, getRepUnit().id);
                    }
                } else {
                    handleFormError({}, "Can not activate a cluster without a rep unit");
                    return;
                }
            } else {
                await clusterService.updateCluster(
                    {
                        active_status: cluster.status,
                        name: cluster.name,
                        internal_notes: cluster.description
                    },
                    cluster.id
                );
            }

            appToast.success("Cluster updated successfully!");
        } catch (error) {
            handleFormError(error, "Error updating cluster");
        } finally {
            setIsHeaderLoading(false);
        }
    };

    const handleFormError = (error, defaultMessage) => {
        console.error(error);
        if (_.has(error, "isHandled")) {
            for (let errorMessage of error.messages) {
                appToast.error(errorMessage);
            }
            return;
        }

        appToast.error(defaultMessage);
    };

    const getRepUnit = () => {
        return clusterUnits.find(x => x.isRepresentative);
    };

    const getRequiredFieldMessage = () => {
        const errors = [];

        if (cluster.name == "") {
            errors.push("Cluster Name Required");
        }

        return (
            <ul style={{paddingLeft: "10px"}}>
                {errors.map((x, index) => {
                    return <li key={index}>{x}</li>;
                })}
            </ul>
        );
    };

    const GoBackButton = () => {
        return <IconBackArrow className={styles.goBackIcon} onClick={() => history.push(`/complexTab/${complexId}`, {complexId})} />;
    };

    const AddUnitsButton = () => {
        if (clusterId === "") {
            return null;
        }

        if (selectedUnits.length > 0) {
            return null;
        }

        return (
            <Button type="primary" onClick={() => history.push(`/addClusterUnit/${complexId}/${clusterId}`)} width="auto">
                Add Units
            </Button>
        );
    };

    const CancelButton = () => {
        if (clusterId !== "") {
            return null;
        }

        if (selectedUnits.length > 0) {
            return null;
        }

        return (
            <Button
                type="primary"
                onClick={() => history.push(`/complexTab/${complexId}`, {complexId: complexId})}
                width="auto"
                className={styles.cancelButton}
            >
                Cancel
            </Button>
        );
    };

    const SaveButton = () => {
        if (selectedUnits.length > 0) {
            return null;
        }

        return (
            <Tippy
                content={getRequiredFieldMessage()}
                {...{
                    arrow: true,
                    animation: "shift-away",
                    duration: 150,
                    delay: 300,
                    size: "large"
                }}
            >
                <span style={{display: "inline-block", marginRight: "12px"}}>
                    <Button
                        type="primary"
                        width="auto"
                        className={styles.actionBarButton}
                        onClick={handleFormSubmit}
                        disabled={submitDisabled}
                        pending={submitLoading}
                    >
                        Save
                    </Button>
                </span>
            </Tippy>
        );
    };

    const ClearSelectedButton = () => {
        if (selectedUnits.length > 0) {
            return (
                <Icon
                    className={styles.clearSelectedIcon}
                    id="trash-icon"
                    onClick={() => {
                        setSelectedUnits([]);
                    }}
                    xLinkHref="#v-icon-ui-x"
                />
            );
        }
        return null;
    };

    const MoveUnitSelect = () => {
        if (selectedUnits.length === 0) {
            return null;
        }

        if (clusters.length === 0) {
            return null;
        }

        const clustersFiltered = _.cloneDeep(clusters);
        _.remove(clustersFiltered, x => x.id == +clusterId);

        return (
            <div>
                <select
                    style={{color: "#655655", borderRadius: "0px", height: "40px", fontSize: "16px"}}
                    className={actionStyles.selectCluster}
                    onChange={e => {
                        const cluster = JSON.parse(e.target.value);
                        switch (cluster.id) {
                            case "0":
                                console.debug(":parrot-cuack");
                                break;
                            case "key-level":
                                MoveUnitToKeyLevel();
                                break;
                            default:
                                MoveUnitToCluster(cluster);
                                break;
                        }
                    }}
                >
                    <option value={JSON.stringify({id: "0"})} key={0}>
                        Move to
                    </option>
                    <option value={JSON.stringify({id: "key-level"})} key={1}>
                        Key-Level Units
                    </option>
                    {clustersFiltered.map(cluster => (
                        <option value={JSON.stringify(cluster)} key={cluster.id}>
                            {cluster.attributes.name}
                        </option>
                    ))}
                </select>
                <ClearSelectedButton />
            </div>
        );
    };

    const MoveUnitToKeyLevel = async () => {
        try {
            setIsHeaderLoading(true);
            const clusterService = new ClusterService(authManager.getJwt());
            const complexService = new ComplexService(authManager.getJwt());
            await clusterService.removeClusterUnits(selectedUnits, Number(clusterId));
            await complexService.createComplexUnits(selectedUnits, Number(complexId));
            setSelectedUnits([]);
            setReloadUnits(reloadUnit + 1);
            appToast.success("Units have been successfully added to Key-Level");
        } catch (e) {
            console.error(e);
            appToast.error("Error moving units");
        } finally {
            setIsHeaderLoading(false);
        }
    };

    const validateMaxOccupancy = (unitSelected, repUnit) => {
        if (_.isNil(repUnit))
            return true;
        return repUnit.attributes.max_occupancy <= unitSelected.attributes.max_occupancy;
    }

    const setRepUnitFromSelectedCluster = async(selectedCluster:number) => {
        let localCluster;
        const clusterService = new ClusterService(authManager.getJwt());
        localCluster = await clusterService.getClusterWithUnitsInclude(Number(selectedCluster));
        const representativeUnit = localCluster.data.relationships.rep_unit.data;
        const unitService = new UnitsService(authManager.getJwt());
        const repUnitData = await unitService.getUnitByUnitId(representativeUnit.id);
        return repUnitData;
    }

    const MoveUnitToCluster = async selectedCluster => {
        try {
            const repUnitFromSelectedCluster = await setRepUnitFromSelectedCluster(selectedCluster.id);
            let errorUnits = []
            selectedUnits.forEach(x => {
                const unit = clusterUnits.find(unit => unit.id === x);
                if (!validateMaxOccupancy(unit, repUnitFromSelectedCluster)) {
                    errorUnits.push(unit.attributes.legacy_unit_id);
                }
            })
            if (errorUnits.length === 0) {
                setIsHeaderLoading(true);
                const clusterService = new ClusterService(authManager.getJwt());
                await clusterService.removeClusterUnits(selectedUnits, Number(clusterId));
                await clusterService.addUnitToCluster(selectedUnits, selectedCluster.id);
                setSelectedUnits([]);
                setReloadUnits(reloadUnit + 1);
                appToast.success(`Units have been successfully added to ${selectedCluster.attributes.name}`);
            } else {
                appToast.error(
                    `Unit(s) ${errorUnits} can not be added to cluster ${selectedCluster.attributes.name} because max occupancy is minor that rep unit ${repUnitFromSelectedCluster.attributes.legacy_unit_id}. Please unselect it/them and move the rest of the selected units`
                );
                setIsHeaderLoading(false);
            }
        } catch (e) {
            console.error(e);
            appToast.error("Error moving units");
        } finally {
            setIsHeaderLoading(false);
        }
    };

    const renderButtons = () => {
        if (isHeaderLoading || fetchingCluster) {
            return <Loader size="12px" className={styles.center} />;
        }

        return (
            <div className={actionStyles.actionButtonsContainer}>                
                <Can I={Ability.Action.Update} a={Ability.Entity.Cluster}>
                    <MoveUnitSelect />
                    <CancelButton />
                    <AddUnitsButton />
                    <SaveButton />
                </Can>
            </div>
        );
    };

    return (
        <div className={actionStyles.actionBarWrapper}>
            <div className={actionStyles.backToSearchLink}>
                <GoBackButton />
                <p className={actionStyles.titleColor}>{clusterId === "" ? "Create a new Cluster" : "Edit Cluster"}</p>
            </div>
            <div className={actionStyles.actionButton}>{renderButtons()}</div>
        </div>
    );
};
