import * as React from "react";
import styles from "./BetterLoader.module.scss";
import classNames from "classnames";

export default function BetterLoader({
  size,
  className = "",
  color = "#0078AB",
}) {
  const bounceStyle = {
    width: size,
    height: size,
    borderRadius: size,
    backgroundColor: color,
  };

  const createBounceDivs = (num) => {
    let divs = [];

    for (let i = 0; i < num; i++) {
      divs.push(
        <div
          key={i}
          className={styles[`${"bounce" + i}`]}
          style={bounceStyle}
        />
      );
    }

    return divs;
  };

  return (
    <div
      style={{ textAlign: "center" }}
      className={classNames(styles.loaderDots, className)}
    >
      {createBounceDivs(3).map((div) => div)}
    </div>
  );
}
