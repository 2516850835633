import {defineAbility} from "@casl/ability";
import {authManager} from "@common/authentication";
import _ from "lodash";

export const Ability = {
    Action: {
        Read: "read",
        Update: "update",
        Create: "create",
    },
    Entity: {
        Complex: "Complex",
        Cluster: "Cluster",
    },
};

export default defineAbility((can) => {
    const userInfo = authManager.getInfoFromAdmin<any>();
    if (_.get(userInfo, "permissions.complexes_view", false) === true) {
        can(Ability.Action.Read, Ability.Entity.Complex);
        can(Ability.Action.Read, Ability.Entity.Cluster);

        if (_.get(userInfo, "permissions.complexes_edit", false) === true) {
            can(Ability.Action.Update, Ability.Entity.Complex);
            can(Ability.Action.Create, Ability.Entity.Complex);
            can(Ability.Action.Update, Ability.Entity.Cluster);
            can(Ability.Action.Create, Ability.Entity.Cluster);
        }
    }
});
