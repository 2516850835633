import React from "react";
import styles from "./ClusterTable.module.scss";
import {SelectableTable} from "../../../../widget";
import {TableUtil} from "../../../../../utils/table.util";
import {Can} from "../../../../../permissions/Can";
import { Ability } from "../../../../../permissions/Ability";
import ability from "../../../../../permissions/Ability";

interface ClusterTableProps {
    complexId: number;
    clusters: any[];
    onClusterRemove: any;
}

export const ClusterTable: React.FunctionComponent<ClusterTableProps> = props => {
    const {complexId, clusters, onClusterRemove} = props;

    const formatClusters = (clusters: any) => {
        const menuActions  = ability.can(Ability.Action.Update, Ability.Entity.Complex) ? [
            {
                label: "Delete Cluster",
                action: onClusterRemove,
                title: "Delete Cluster",
                loadingOption: false
            }
        ] : [];

        return TableUtil.getFormattedCluster(complexId, clusters, menuActions);
    };

    if (!clusters || clusters.length === 0) {
        return <React.Fragment />;
    }

    const formattedCluster = formatClusters(clusters);
    return (
        <Can I={Ability.Action.Read} a={Ability.Entity.Complex}>
            <div className={styles.topSection}>
                <span className={styles.keyUnitLabel}>{`${clusters.length} Clusters`}</span>
            </div>
            <SelectableTable
                data={formattedCluster}
                columns={TableUtil.getClusterColumns()}
                selectedItems={[]}
                pageSizeMax={20}
                filterCustomStyle={{background: "rgb(0, 51, 73)"}}
                hideCheck={true}
            />
        </Can>
    );
};
