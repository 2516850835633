import React, {MouseEventHandler} from "react";
import styles from "./UnitAddList.module.scss";
import {getStatus, SelectableTable} from "../../../widget";
import {Configuration} from '@common/configuration';

interface UnitAddHeaderProps {
    units: Array<any>;
    selectedUnits: Array<any>;
    handleUnitSelection: Function;
    toggleSearchForm: MouseEventHandler<any>;
    showSearchForm: boolean;
}

export const UnitAddList: React.FunctionComponent<UnitAddHeaderProps> = (props) => {
    const columns = [
        {
            Header: "Unit ID",
            accessor: "unitID",
            width: 100,
        },
        {
            Header: "Unit Code",
            accessor: "unitCode",
            width: 150,
        },
        {
            Header: "Unit Name",
            accessor: "unitName",
        },
        {
            Header: "Address",
            accessor: "address",
        },
        {
            Header: "Status",
            accessor: "status",
        },
    ];

    const formattedUnitsForTable = (units) => {
        let data = [];
        units.forEach(function (unit) {
            data.push({
                _id: unit.id,
                unitID: unit.attributes.legacy_unit_id,
                unitCode: (
                    <a target="_blank" href={Configuration.adminUrl() + "/admin/dashboard/units/edit?UnitID=" + unit.attributes.legacy_unit_id}>
                        {unit.attributes.unit_code}
                    </a>
                ),
                unitName: unit.attributes.name,
                address: unit.attributes.address.address_1,
                status: getStatus(unit),
            });
        });

        return data;
    };

    let formattedUnits = formattedUnitsForTable(props.units);

    return (
        <div className={styles.tableContainer}>
            {props.units.length < 1 ? (
                <div className={styles.resultMessage}>
                    <span>No results found. Please try again.</span>
                </div>
            ) : (
                <div>
                    <div className={styles.topSection}>
                        {props.selectedUnits.length > 0 ? (
                            <span className={styles.unitTotal}>
                                {props.selectedUnits.length} of {props.units.length} selected
                            </span>
                        ) : (
                            <span className={styles.unitTotal}>
                                {props.units.length > 1 ? props.units.length + " results" : props.units.length + " result"}
                            </span>
                        )}
                        <span onClick={props.toggleSearchForm} className={styles.searchAgainText}>
                            {!props.showSearchForm ? "(search again)" : "(close search)"}
                        </span>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-table">
                            <SelectableTable
                                data={formattedUnits}
                                columns={columns}
                                selectedItems={props.selectedUnits}
                                updateSelectedItems={props.handleUnitSelection}
                                pageSizeMax={100}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
